import axios from 'axios';
const axiosClient = axios.create({
    baseURL : process.env.REACT_APP_PARTNERS_API_HOSTNAME,
});

axiosClient.interceptors.response.use(
    function(config) {
        return config;
    },
    function(error){

        if (error.response.status === 401 && window.location.pathname !== '/') {
            localStorage.removeItem('user');
            window.location.href = '/login';
        }
    }
);
export default axiosClient;