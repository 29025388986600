import React from 'react'

//Theme
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

// Routes
import Routes from './routes/Routes';

// States
import AuthState from './context/auth/authState';
import ClientState from './context/client/clientState';
import CommissionState from './context/commission/commissionState';
import PaymentState from './context/payment/paymentState';
import DashboardState from './context/dashboard/dashboardState';
import LandingState from './context/landing/landingState';
import { CookiesProvider } from 'react-cookie';

import { Toast } from './plugins/Toast';

import './components/lang/i18n';

let theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#1A499F',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#184d9f',
    },
    danger: {
      main: 'red'
    }
  },
  typography: {
    fontFamily: 'Montserrat',
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <AuthState>
          <ClientState>
            <CommissionState>
              <PaymentState>
                <DashboardState>
                  <LandingState>
                    <Routes/>
                    <Toast />
                  </LandingState>
                </DashboardState>
              </PaymentState>
            </CommissionState>
          </ClientState>
        </AuthState>
      </ThemeProvider>
    </CookiesProvider>
  );
}

export default App;
