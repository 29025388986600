import { GET_LANDING_LANGUAGES, SET_LANDING_LANGUAGE } from '../../types'

const landingReducer =  (state, action) => {
    switch (action.type) {
        case GET_LANDING_LANGUAGES:
            return {
                ...state,
                languages: action.payload
            };
        case SET_LANDING_LANGUAGE:
            return {
                ...state,
                lang: action.payload
            };
        default:
            return {
                ...state,
                lang: action.payload
            };
    }
};

export default landingReducer;
