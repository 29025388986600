import {
    GET_CLIENTS,
    GET_INFO_CLIENT,
    GET_CLIENT_COMMISSIONS,
    GET_CLIENT_PROSPECTS,
    REGISTER_PROSPECTS,
    ERROR_PROSPECT,
    CLEAN,
    GET_LANGUAGES,
    GET_PHONE_CODES
} from '../../types'

const clientReducer = (state, action) => {
    switch (action.type) {

        case GET_CLIENTS:
            return{
                ...state,
                clients: action.payload
            };
        case GET_INFO_CLIENT:
            return{
                ...state,
                client: action.payload
            };
        case GET_CLIENT_COMMISSIONS:
            return{
                ...state,
                clientCommissions: action.payload
            };
        case GET_CLIENT_PROSPECTS:
            return{
                ...state,
                prospects: action.payload
            };
        case REGISTER_PROSPECTS:
            return{
                ...state,
                message: action.message,
                register_response: 'success',
            };
        case ERROR_PROSPECT:
            return{
                ...state,
                message: action.payload,
                register_response: 'error',
            };
        case GET_PHONE_CODES:
            return{
                ...state,
                phone_codes: action.payload,
            };
        case GET_LANGUAGES:
            return {
                ...state,
                languages: action.payload
            }
        default:
            return{
                ...state,
                message: '',
                register_response: '',
            };
    }
}

export default clientReducer;