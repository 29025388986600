import { Button, Container, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    join: {
        backgroundColor: '#00abec',
        paddingTop: 80,
        paddingBottom: 80,
        textAlign: 'center',
    },
    container: {
        [theme.breakpoints.up(1279)]: {
            maxWidth: 1140,
        },
        [theme.breakpoints.up(1600)]: {
            maxWidth: 1500,
        },
    },
    star: {
        width: 30,
        height: 'auto',
        marginBottom: 20,
    },
    title: {
        fontWeight: 900,
        fontSize: '2.6rem',
        textTransform: 'uppercase',
        '& span': {
            color: '#ffffff',
        },
    },
    btn: {
        color: '#000000',
        background: '#d5f8ff',
        borderRadius: 0,
        padding: '5px 32px',
        fontSize: '1rem',
        marginRight: '15px',
        fontWeight: '500',
        '&:hover': {
            background: '#b7f2fd',
        }
    },
}));

function Join() {
    const classes = useStyles();
    const { t } = useTranslation();

	return (
        <Box className={classes.join}>
            <Container className={classes.container}>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <img className={classes.star} alt={'White star'} src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-Blanco-2.png`}/>
                        <Typography gutterBottom variant="h2" className={classes.title}>
                            {t('landing.contact.title.part1')}
                            <br />
                            <span>
                                {t('landing.contact.title.part2')}
                            </span>
                        </Typography>
                        <Button href="https://docs.google.com/forms/d/e/1FAIpQLScBTW0l3qGgvZgZwgaXUmBEZe8_S-J_mz2Nh8hGYEMhObgQ5w/viewform" target="_blank" className={classes.btn}>
                            {t('landing.benefits.form.button')}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
	);
}

export default  withRouter(Join);
