import React, { useReducer } from 'react';
import landingReducer from './landingReducer';
import LandingContext from './landingContext';

import axiosClient from '../../config/axios'

import { GET_LANDING_LANGUAGES, SET_LANDING_LANGUAGE } from '../../types'

const LandingState = (props) => {
    const initialLang = (localStorage.getItem('envia_partner_lenguage')) ? localStorage.getItem('envia_partner_lenguage') : 'es-MX';

    const initialState = {
        lang: '',
        languages: [],
    };

    const [ state, dispatch ] = useReducer(landingReducer, initialState);

    const getLangs = () => {
        axiosClient({
            method: 'GET',
            url: `${process.env.REACT_APP_ENVIA_API_QUERIES_HOSTNAME}/languages`,
            headers: { 'Content-Type': 'application/json' },
        }).then(response => {
            const langs = response.data.data;
            const selectedLang = langs.find((lang) => lang.id === initialLang);
            
            dispatch({
                type: GET_LANDING_LANGUAGES,
                payload: langs,
            });

            setLang(selectedLang.id);
        })
        .catch(error => {
            console.log(error);
        });
    }
    const setLang = (lang) => {
        dispatch({
            type: SET_LANDING_LANGUAGE,
            payload: lang,
        });

        localStorage.setItem('envia_partner_lenguage', lang);
    }

    return (
        <LandingContext.Provider
            value={{
                languages: state.languages,
                lang: state.lang,
                getLangs,
                setLang,
                dispatch,
            }}
        >
            {props.children}
        </LandingContext.Provider>
    );
};

export default LandingState;
