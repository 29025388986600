import React from 'react';

// Material-UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';

// Icons
import Person from '@material-ui/icons/Person';

// Component
import Menu from '../../components/layouts/Menu';
import CustomButton from '../../components/info/CustomButton';


const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold',
    },
    paper: {
        padding: 20,
    },
    paperBotton: {
        backgroundColor: '#e1e1e1',
        padding: 10,
    },
    paperBtn: {
        padding: 20,
        paddingBottom: 0,
    },
    contentBtn: {
        borderRadius: 14,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#184d9f',
        padding: 2,
        width: 'max-content'
    },
    avatarGreen: {
        color: '#fff',
        backgroundColor: green[500],
    },
    nameUser: {
        color: '#184d9f',
        fontWeight: 'bold',
    }

}));

const Team = () => {
    const classes = useStyles();

    return (
        <Menu menuCurrent={'Team'}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.title}>Team</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Owners</Typography>
                    <Typography variant="body1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                                Quos blanditiis tenetur unde suscipit, 
                                                quam beatae rerum inventore consectetur, neque doloribus, 
                                                cupiditate numquam dignissimos laborum fugiat deleniti? 
                                                Eum quasi quidem quibusdam.</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Paper>
                        <div className={classes.paperBtn}>
                            <div className={classes.contentBtn}>
                                <Button variant="contained" color="primary" style={{fontSize: 10, borderRadius: 10}}>
                                Active
                                </Button>
                                <Button color="primary" style={{fontSize: 10}}>
                                Invited
                                </Button>
                            </div>
                        </div>
                        <div className={classes.paper}>
                            <Grid container>
                                <Grid item xs={2}>
                                    <Avatar className={classes.avatarGreen}>
                                        <Person />
                                    </Avatar>

                                </Grid>
                                <Grid item xs={4} style={{alignSelf: 'center'}}>
                                    <Typography variant="body1" className={classes.nameUser}>Envia Shipping</Typography>
                                </Grid>
                                <Grid item xs={6} style={{alignSelf: 'center'}}>
                                    <Typography variant="body1">Last login about 24 hours ago</Typography>
                                </Grid>
                            </Grid>
                        </div>


                        <div className={classes.paperBotton}>
                            <CustomButton text={'Invite owner'} styleButton={{fontSize: 12}} />
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <Divider style={{marginTop: 40, marginBottom: 40}}/>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Staff member</Typography>
                    <Typography variant="body1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                                Quos blanditiis tenetur unde suscipit, 
                                                quam beatae rerum inventore consectetur, neque doloribus, 
                                                cupiditate numquam dignissimos laborum fugiat deleniti? 
                                                Eum quasi quidem quibusdam.</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Paper>
                        <div className={classes.paperBtn}>
                            <div className={classes.contentBtn}>
                                <Button variant="contained" color="primary" style={{fontSize: 10, borderRadius: 10}}>
                                Active
                                </Button>
                                <Button color="primary" style={{fontSize: 10}}>
                                Invited
                                </Button>
                            </div>
                        </div>
                        <div className={classes.paper}>
                            <Typography variant="h6" >No staff members</Typography>
                            <Typography variant="body1" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dolor enim,
                                                            bibendum sed justo quis, accumsan eleifend.</Typography>
                        </div>


                        <div className={classes.paperBotton}>
                            <CustomButton text={'Invite staff member'} styleButton={{fontSize: 12}} />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Menu>
    )
}

export default Team;