import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    aboutUs: {
        background: '#ffffff',
        paddingBottom: 80,
        paddingTop: 80,
    },
    container: {
        [theme.breakpoints.up(1279)]: {
            maxWidth: 1140,
        },
        [theme.breakpoints.up(1600)]: {
            maxWidth: 1500,
        },
    },
    textSecondary: {
        color: '#00abec',
    },
    textAlignResponsive:{
        textAlign:'left',
        [theme.breakpoints.down('sm')]:{
            textAlign:'center',
        },
    },
    title: {
        color: '#050629',
        fontWeight: 800,
        fontSize: '2.6rem',
        textAlign:'left',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            textAlign:'center',
        },
    },
    titleText: {
        color: '#00abec',
        textAlign:'left',
        fontSize: '1.3rem',
        [theme.breakpoints.down('sm')]: {
            textAlign:'center',
        },
        '&::after': {
            content: '""',
            display: 'block',
            height: 4,
            width: 60,
            marginTop: 10,
            backgroundColor: '#00abec',
            [theme.breakpoints.down('sm')]: {
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
    },
    btn: {
        color: '#ffffff',
        background: '#01a8f1',
        borderRadius: 0,
        padding: '5px 32px',
        fontSize: '1rem',
        marginRight: '15px',
        fontWeight: '400',
        '&:hover': {
            background: '#2cb3ec',
            color: 'white',
        },
        [theme.breakpoints.down('sm')]: {
            textAlign:'center',
            marginBottom: 50,
        },
    },
}));

function AboutUs() {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
        <div className={classes.aboutUs}>
            <Container className={classes.container}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Box clone order={{xs:2, md:1}}>
                        <Grid item xs={12} md={6}>
                            <img width="100%" alt="Envia" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-About.png`} />
                        </Grid>
                    </Box>
                    <Box clone order={{xs:1, md:2}}>
                        <Grid item xs={12} md={6}>
                            <Box mx={3}>
                                <div>
                                    <Box className={classes.textAlignResponsive}>
                                        <Typography className={classes.title} gutterBottom variant="h2">
                                            <Box fontWeight="fontWeightBold">
                                                {t('landing.aboutus.title')}
                                            </Box>
                                        </Typography>
                                    </Box>
                                    <Typography className={classes.titleText} gutterBottom variant="h6">
                                            {t('landing.aboutus.text1')}
                                        <br />
                                        <br />
                                        {t('landing.aboutus.text2')}
                                    </Typography>
                                    <Box className={clsx("section-space--pt_40", classes.textAlignResponsive)}>
                                        <Button component={Link} to="/side-login" variant="contained" className={classes.btn}>
                                            {t('landing.knowmore.title')}
                                        </Button>
                                    </Box>
                                </div>					
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Container>
        </div>
	);
}

export default  withRouter(AboutUs);
