import React, { useState, useContext, useRef, } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  useHistory,
} from "react-router-dom";

//Langs
import { useTranslation } from 'react-i18next';

// Material-UI
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


// Icons
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';

//Files
import Logo from '../../assets/images/logo.svg';

//Context
import AuthContext from '../../context/auth/authContext';

// Components
import Loading from '../info/Loading';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        background: 'linear-gradient(to right, #1F3592, #00AAE2)'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },

    avatar: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(0),
            padding: '12px 0px 12px 12px'
        },
    },
    nameUser: {
        width: '100%',
        textAlign: 'right',
        paddingRight: 20,
    },
    // necessary for content to be below app bar
    toolbarLogo: {
        ...theme.mixins.toolbar,
        backgroundColor: '#1F3592',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#1A499F',
        borderRightWidth: 0
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    menuItemActive: {
        color: '#00abe2',
        fontWeight: 'bold',
    },
    menuItem: {
        color: 'white',
    },
    menuItemIcon: {
        color: '#115fad',
    },
    menuItemIconActive: {
        color: '#00abe2',
    },
    menuItemMenu: {},
    menuItemMenuActive: {
        backgroundColor:'#1958b9'
    },
}));


const MenuDrawer = (props) => {
    const { t } = useTranslation();

    const { window, menuCurrent } = props;
    const history = useHistory();
    const { user, logout } = useContext(AuthContext)
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const refBtnProfile = useRef(null)
    const [openMenuProfile, setOpenMenuProfile] = useState(false);
    const [loading, setLoading] = useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleClick = () => {
        setOpenMenuProfile(true);
    };

    const handleClose = () => {
        setOpenMenuProfile(false);
    };

    const onLogout = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            history.replace('/');
            logout();
        }, 2000);
    }

    const drawer = (
        <div>
            <div className={classes.toolbarLogo} >
                <img src={Logo} height="40" alt="ENVIA.COM"/>
            </div>
            <Divider />
            <List>
                {[{label: t('menu.dashboard'), path: "/dashboard"}, {label: t('menu.prospects'), path: "/prospects"}, {label: t('menu.clients'), path: "/clients"}, {label: t('menu.payments'), path: "/payments"}, {label: t('menu.commission'), path: "/commission"}, /* {label: t('menu.dashboard'), path: "/team"}, */ {label: t('menu.support'), path: "/support"}].map((item, index) => (
                    <Link style={{textDecoration: 'none'}} to={item.path} key={item.label}>
                        <ListItem button key={item.label}  className={menuCurrent === item.label ? classes.menuItemMenuActive : classes.menuItemMenu}>
                            <ListItemIcon  className={menuCurrent === item.label ? classes.menuItemIconActive : classes.menuItemIcon }>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                            <ListItemText primary={item.label} className={menuCurrent === item.label ? classes.menuItemActive : classes.menuItem }/>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Loading open={loading}/>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div style={{width: '100%'}}>
                        <Typography variant='h6' noWrap className={classes.nameUser}>
                            Hi, {user.userName} <br />
                        </Typography>
                        <p style={{margin: '0px'}} className={classes.nameUser}>Partner ID # {user.partnerId}</p>
                    </div>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={handleClick}
                        ref={refBtnProfile}
                        className={classes.avatar}
                    >
                        <Avatar>{user.userName.charAt(0)}</Avatar> 
                    </IconButton>

                    <Menu
                        id="simple-menu"
                        anchorEl={refBtnProfile.current}
                        keepMounted
                        open={openMenuProfile}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => history.replace('/profile')}>Profile</MenuItem>
                        <MenuItem onClick={onLogout}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                        paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                        paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
}

MenuDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default MenuDrawer;
