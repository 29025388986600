import React, { useEffect, useRef, useContext, useState } from 'react';

//Langs
import { useTranslation } from 'react-i18next';

// Material-UI
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// Other
import { Line } from 'react-chartjs-2';
import { useCookies } from 'react-cookie';

//Context
import DashboardContext from '../../context/dashboard/dashboardContext'
import AuthContext from '../../context/auth/authContext'

// Component
import Menu from '../../components/layouts/Menu';

//Modals
import RegisterPay from '../../components/modals/RegisterPay';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold',
    },
    subtitle: {
        fontWeight: 'bold',
        fontSize: '1.4em',
        color: 'gray'
    },
    paper: {
        padding: 20,
    },
    paperChart: {
        padding: 20,
        borderRadius: 20,
        height: '100%',
    },
    titleChart: {
        textAlign: 'center',
        fontSize: '1.4em',
    },
    countChart: {
        fontSize: '2em',
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 10,
    },
    contentTextChart: {
        fontSize: 10,
        textAlign: 'center',
        height: 30,
    },
    paperChartClient: {
        padding: 20,
        borderRadius: 20,
    },
    titleChartClient: {
        fontSize: '1.4em',
    },
    titleChartIncidents: {
        marginTop: 10,
        fontSize: '1.4em',
    },
    titleChartHorizontal: {
        fontSize: 10,
    },
    valueChartHorizotal: {
        textAlign: 'center',
        fontSize: 10,
    },
    heading: {
        fontSize: 14,
    },
    hiddeResponsive: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    }
}));

const Dashboard = (props) => {
    var qs = require('qs');
    const { t } = useTranslation();
    const classes = useStyles();

    const { user } = useContext(AuthContext)
    const [cookies, setCookie] = useCookies(['show_register_pay']);
    
    const [openModal, setOpenModal] = useState(false);

    const { 
        registers, getPartnerRegisteredClients, 
        shipments, getPartnerClientsShipments,
        commission, getPartnerAvgCommission,
    } = useContext(DashboardContext);

    const [startDate, setStartDate] = useState(new Date());
    var months = [], registers_data = [], shipments_data = [], shipments_counter = 0, companies = 0;

    
    useEffect(() => {
        getPartnerRegisteredClients(startDate.getFullYear());
        getPartnerClientsShipments(startDate.getFullYear());
        getPartnerAvgCommission(startDate.getFullYear());
        var params = qs.parse(props.history.location.search, { ignoreQueryPrefix: true });
        if(params.authorization){
            user.hasAccounts = 1;
        }
    }, []);

    useEffect(() => {
        if(!user.hasAccounts){
            if(!cookies.payValidation){
                setOpenModal(true);
            }
        }
    }, []);

    registers.map((row) => {
        months.push(row.name);
        registers_data.push(row.companies);
        companies += parseInt(row.companies);
    });

    shipments.map((row) => {
        shipments_data.push(row.shipments);
        shipments_counter += parseInt(row.shipments);
    });

    const data = {
        labels: months,
        datasets: [
            {
                label: t('dashboard.graphic.labelClients'),
                data: registers_data,
                fill: false,
                backgroundColor: '#1565c0',
                borderColor: '#2196f3',
            },{
                label: t('dashboard.graphic.labelShipments'),
                data: shipments_data,
                fill: false,
                backgroundColor: '#009688',
                borderColor: '#1de9b6',
            },
        ],
    };
      
    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };


    return (
        <Menu menuCurrent={'Dashboard'}>
            <RegisterPay open={openModal} callback={setOpenModal}></RegisterPay>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.title}>{t('menu.dashboard')}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" className={classes.subtitle}>{t('dashboard.subtitle')}: {startDate.getFullYear()}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Paper className={classes.paperChart}>
                                <Typography className={classes.titleChart}>{t('dashboard.cardGrossCommission')}</Typography>
                                <Typography className={classes.countChart} style={{color: '#FF8852'}}>$ {commission.commisssions} USD</Typography>
                                <Typography className={classes.contentTextChart}>{t('dashboard.cardGrossCommissionContext')}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper className={classes.paperChart}>
                                <Typography className={classes.titleChart}>{t('dashboard.cardClients')}</Typography>
                                <Typography className={classes.countChart} style={{color: '#1A499F'}}>{companies}</Typography>
                                <Typography className={classes.contentTextChart}>{t('dashboard.cardClientsContext')}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper className={classes.paperChart}>
                                <Typography className={classes.titleChart}>{t('dashboard.shipments')}</Typography>
                                <Typography className={classes.countChart} style={{color: '#30DB9F'}}>{shipments_counter}</Typography>
                                <Typography className={classes.contentTextChart}>{t('dashboard.shipmentsContext')}</Typography>
                            </Paper>
                        </Grid>
                        {/* -------- Chart Bar Client -------- */}
                        <Grid item xs={12} className={classes.hiddeResponsive}>
                            <Paper className={classes.paperChartClient}>
                                <Typography className={classes.titleChartClient}>{t('dashboard.graphicContext')}</Typography>
                                <Line data={data} options={options} height={100}/>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Menu>
    )
}

export default Dashboard;