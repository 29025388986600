import React, { useState, useContext, useEffect, memo } from 'react';
import { toast } from 'react-hot-toast';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

//icons
import CancelIcon from '@material-ui/icons/Cancel';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import PersonIcon from '@material-ui/icons/Person';

// Context
import AuthContext from '../../context/auth/authContext'
import PaymentContext from '../../context/payment/paymentContext'

//Langs
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import logoColor from '../../assets/images/logo-color.svg';

import {
    CLEAN,
} from '../../types'

const useStyles = makeStyles((theme) => ({
    containerLogos: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fas: {
        margin: '15px',
        color: '#9e9e9e',
    },
    boxLogo: {
        width: '150px',
        height: '70px',
        display: 'flex',
        boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
    },
    title: {
        textIlign: 'center',
        marginBottom: '15px',
        color: '#616161',
    },
    companyLabel: {
        margin: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerLabel: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '15px',
        borderRadius: '20px',
        backgroundColor: '#e0f7fa',
        border: '1px solid #fafafa',
    },
    companyLogo: {
        margin: '5px',
        boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)',
        borderRadius: '100%',
        width: '25px',
        height: '25px',
        backgroundColor: '#fff',
    },
    formIntegration: {
        textAlign: 'center',
    },
    terms: {
        textAlign: 'justify',
        fontSize: '0.8em'
    },
    createAccount: {
        textAlign: 'center',
        
    }
}));

const RegisterPay = ({open: openModal, callback: setOpenModal}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { user,reloadSession } = useContext(AuthContext);
    const [ , setCookie] = useCookies(['show_register_pay']);
    
    const { updateEcartPayEmail, update_email_response, message, dispatch  } = useContext(PaymentContext);
    const [ , setLoading] = useState(false);

    const [buttonState, setButtonState] = useState(true);

    const [email, setEmail] = useState(user.ecartPayEmail)

    const handleClose = () => {
        setOpenModal(false);
        if(update_email_response === 'success'){
            setCookie('payValidation', true);
            reloadSession();
            dispatch({
                type: CLEAN,
            })
        }
    };

    useEffect(() => {
        if(email){
            setButtonState(false);
        }else{
            setButtonState(true);
        }
    }, [email]);

    useEffect(() => {
        if (update_email_response === 'success') {
            setLoading(false)
            toast.success(message);
            handleClose();
        }
        
        if (update_email_response === 'error') {
            setLoading(false)
            toast.error(message);
        }
    }, [update_email_response]);

    const onChange = (e) => {
        setEmail(e.target.value);
    }

    function handleSubmit(ev) {
        ev.preventDefault();
        if(email){
            updateEcartPayEmail(email);
        }
    }

    return (
        <Dialog open={openModal} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('payments.modal.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText style={{textAlign: 'center'}}>
                    <h5 className={classes.title}>{t('payments.modalPay.subtitle')}</h5>
                    <div className={classes.containerLogos}>
                        <span className={classes.boxLogo}>
                        <img
                            src={logoColor}
                            alt='ecart pay'
                            width={120}
                            height={40}
                        />
                        </span>
                        <SyncAltIcon style={{margin: '10px'}}/>
                        <a href={`${process.env.REACT_APP_ECART_PAY_API}/register`} target="_BLANK" rel="noreferrer">
                        <span className={classes.boxLogo}>
                            <img
                            src={`https://pay.ecart.com/img/logo-negro.svg`} 
                            alt='ecart pay'
                            width={120}
                            height={40}
                            />
                        </span>
                        </a>
                    </div>
                    <div className={classes.companyLabel}>
                        <label className={classes.containerLabel}>
                        <span className={classes.companyLogo}>
                            <PersonIcon/>
                        </span>
                        <span>{user.userName}</span>
                        </label>
                    </div>
                    <p align="center">{t('payments.modalPay.account_yet')}</p>
                    <a href={`${process.env.REACT_APP_ECART_PAY_API}/register`} className={classes.createAccount} target="_BLANK" rel="noreferrer">
                        <Button variant="contained" color="primary" type="button">
                        {t('payments.modalPay.create_account')}
                        </Button>  
                    </a><br />
                    <form onSubmit={handleSubmit}>
                        <TextField
                            margin="dense"
                            id="email"
                            name="email"
                            label={t('payments.modalPay.form.email')}
                            type="email"
                            onChange={ onChange }
                            defaultValue={email}
                            fullWidth
                        />
                        <DialogActions>
                            <Button startIcon={ <CancelIcon/> } onClick={handleClose} style={{float: 'left'}} color="primary">
                                {t('payments.modal.buttons.cancel')}
                            </Button>
                            <Button disabled={buttonState} startIcon={ <ExitToAppIcon/> } variant="contained" color="primary" type="submit">
                                    {t('payments.modalPay.buttons.accept')}
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default memo(RegisterPay);
