import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SearchIcon from '@material-ui/icons/Search';

import CustomTable from './CustomTable';

//Langs
import { useTranslation } from 'react-i18next';


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#0085c7',
        color: theme.palette.common.white,
        borderLeftColor: 'white',
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
        textAlign: 'center',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);



function Row(props) {
	const classes = useRowStyles();
	const { t } = useTranslation();
	const [open, setOpen] = React.useState(false);

	const commissions = JSON.parse(props.row['details']);

	const columns = [
		{ id: 'carrier_name', complement: 'carrier_img', label: t('commission.table.carrier'), view: (el) => {
            return (
                <Box style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{textAlign: 'center', marginRight: 20}}>
                        <img src={`${process.env.REACT_APP_S3_PATH}/uploads/logos/carriers/${el.complement}.svg`} width="50" alt="card"/>
                    </div>
                    <Typography style={{textAlign: 'center' ,color: '#184d9f', fontWeight: 'bold'}}>{el.value}</Typography>
                </Box>
            )
        }},
		{ id: 'month', label: t('payments.table.header.month'), view: (value) => {
			return (
				<Typography style={{textAlign: 'center'}}>{value}</Typography>
			)
		}},
		{ id: 'shipments', label: t('payments.table.header.shipments'), view: (value) => {
			return (
				<Typography style={{textAlign: 'center' ,color: '#184d9f', fontWeight: 'bold'}}>{value}</Typography>
			)
		}},
		{ id: 'commission', label: t('payments.table.header.commission'), view: (value) => {
			return (
				<Typography style={{textAlign: 'center' }}> ${value} USD</Typography>
			)
		}},
	];

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				{props.columns.map((column) => {
					const value = props.row[column.id];
					var complement = null;
					(column.hasOwnProperty('all')) ? complement = props.row[column.complement] : complement = null;
					return (
						<TableCell align={column.align} >
							{
								(!column.hasOwnProperty('all')) ?
									column.hasOwnProperty('view') 
									? column.view(column.format && typeof value === 'number' ? column.format(value) : value) 
									: column.format && typeof value === 'number' ? column.format(value) : value
								:
									column.hasOwnProperty('view') 
										? column.view(props.row) 
										: column.format && typeof value === 'number' ? column.format(value) : value
							}
						</TableCell>
					);
				})}
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
					<Collapse 
						in={open}
					>
						<Box margin={1}>
							<Typography variant="h6" gutterBottom component="div">
								{t('payments.table.header.amount')}
							</Typography>
							<CustomTable rows={commissions} columns={columns}/>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

export default function PaymentsTable(props) {
	const { t } = useTranslation();
	const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
		<Paper>
			<TableContainer>
				<Table stickyHeader aria-label="collapsible table">
					<TableHead>
						<TableRow>
							<StyledTableCell />
							{props.columns.map((column) => (
								<StyledTableCell
								key={column.id.toString()}
								align={column.align}
								style={{ minWidth: column.minWidth }}
								>
								{column.label}
								</StyledTableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{props.rows.length >= 0 && props.rows.map((row) => (
							<Row key={row.id} row={row} columns={props.columns} all={props.all}/>
						))}
						{props.rows.length <= 0 && (
							<TableRow>
								<StyledTableCell colSpan={7}>
									<div style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
										<SearchIcon fontSize="large" color="disabled"/>
										<span color="disabled">{t('general.table.noRecords')}</span>
									</div>
								</StyledTableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={props.rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</Paper>
    );
}