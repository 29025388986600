import React from 'react';
import { Link } from 'react-router-dom';

// Material-UI
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';

//Icons
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Component
import Menu from '../../components/layouts/Menu';

//ENV
const REACT_APP_URL_S3 = process.env.REACT_APP_URL_S3;

const languages = [
    {id: "es", label: 'Spanish - Mexico', img: "MX.png", favorite: true},
    {id: "es", label: 'Spanish - Mexico', img: "DE.png", favorite: false},
    {id: "es", label: 'Spanish - Mexico', img: "CA.png", favorite: false},
    {id: "es", label: 'Spanish - Mexico', img: "DE.png", favorite: false},
    {id: "es", label: 'Spanish - Mexico', img: "CA.png", favorite: false},
    {id: "es", label: 'Spanish - Mexico', img: "DE.png", favorite: false},
    {id: "es", label: 'Spanish - Mexico', img: "CA.png", favorite: false},
    {id: "es", label: 'Spanish - Mexico', img: "DE.png", favorite: false},
    {id: "es", label: 'Spanish - Mexico', img: "CA.png", favorite: false},
];


const Profile = () => {
    const classes = useStyles();


    const renderListLanguage = (language) => {

        return(
            <Paper className={classes.paperLanguage}>
                <Box style={{display: 'flex', alignItems: 'center'}}>
                    <Avatar alt="Remy Sharp" src={`${REACT_APP_URL_S3}/enviapaqueteria/uploads/locales/${language.img}`}/>
                    <Typography variant="h6" style={{paddingLeft: 20, width: '80%'}}>{language.label}</Typography>
                    {language.favorite ? <Star style={{width: '10%'}}/> : <StarBorder style={{width: '10%'}}/>}
                </Box>
            </Paper>
        )
    }

    return (
        <Menu menuCurrent={'Profile'}>
            <Grid container spacing={3}>
                <Box style={{marginTop: 20, marginBottom: 20}}>
                    <Link to="/profile"><Typography variant="body1" className={classes.return}> <ArrowBackIcon/> Regresar</Typography></Link>
                </Box>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h6">Configuración de Lenguaje</Typography>
                    <Typography variant="body2">Selecciona el lenguaje de tu preferencia para la plataforma.</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    {languages.map( language => renderListLanguage(language) )}
                </Grid>
            </Grid>
        </Menu>
    )
}



const useStyles = makeStyles((theme) => ({
    return: {
        display: 'flex',
        alignItems: 'center',
        color: '#1F3592',
        fontWeight: 'bold',
        fontStyle: 'none'
    },
    paper: {
        padding: 20,
    },
    paperLanguage: {
        padding: 20,
        marginTop: 10,
        cursor: 'pointer'
    },
    large: {
        width: theme.spacing(14),
        height: theme.spacing(14),
    },
}));

export default Profile;