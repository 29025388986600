import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import LandingContext from '../../../context/landing/landingContext';

const variantsMap = {
    'light': '#ffffff',
    'dark': '#3a3a3a',
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    label: {
        color: (props) => `${variantsMap[props.variant]}`,
        transform: 'translate(14px, 12px) scale(1)',
    },
    labelFocused: {
        color: (props) => `${variantsMap[props.variant]} !important`,
    },
    input: {
        padding: '10px 10px !important',
    },
    select: {
        color: 'white',
        "& .MuiOutlinedInput-notchedOutline": {
            border: (props) => `1px solid ${variantsMap[props.variant]} !important`,
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: (props) => `1px solid ${variantsMap[props.variant]} !important`,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: (props) => `1px solid ${variantsMap[props.variant]} !important`,
        },
    },
    selectedOptionContainer: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            color: (props) => variantsMap[props.variant],
        }
    },
    optionContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    optionImage: {
        width: 20,
        height: 20,
        marginRight: 5,
    },
    icon: {
        fill: (props) => variantsMap[props.variant],
    },
}));

function LanguageSelector ({ variant = 'dark' }) {
    const classes = useStyles({ variant });
    const { t, i18n } = useTranslation();
    const { languages, lang, getLangs, setLang } = useContext(LandingContext);

    const changeLanguage = (event) => {
        const language = event.target.value;
        setLang(language);
        i18n.changeLanguage(language)
    };

    useEffect(() => {
        getLangs();
    }, []);

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
                id="language-selector-label"
                classes={{ root: classes.label, focused: classes.labelFocused}}
            >
                {t('profile.languages')}
            </InputLabel>
            <Select
                className={classes.select}
                labelId="language-selector-label"
                id="language-selector"
                value={lang}
                onChange={changeLanguage}
                label={t('profile.languages')}
                inputProps={{
                    classes: {
                        root: classes.input,
                        icon: classes.icon,
                    },
                }}
                renderValue={(value) => (
                    <div className={classes.selectedOptionContainer}>
                        <img 
                            src={`${process.env.REACT_APP_S3_PATH}/uploads/locales/${value.slice(3,5)}.png`} 
                            alt="Country" 
                            className={classes.optionImage}
                        />
                        <span>
                            {value}
                        </span>
                    </div>
                )}
            >
                {languages.map((language) => (
                    <MenuItem key={language.id} value={language.id}>
                        <div className={classes.optionContainer}>
                            <img 
                                src={`${process.env.REACT_APP_S3_PATH}/uploads/locales/${language.id.slice(3,5)}.png`} 
                                alt="Country" 
                                className={classes.optionImage}
                            />
                            <span>
                                {language.description}
                            </span>
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default LanguageSelector

LanguageSelector.propTypes = {
    variant: PropTypes.oneOf([
        'light',
        'dark',
    ]),
};
