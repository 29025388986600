import {
    GET_PARTNER_COMMISSIONS,
} from '../../types'

const commissionReducer = (state, action) => {
    switch (action.type) {

        case GET_PARTNER_COMMISSIONS:
            return{
                ...state,
                commissions: action.payload
            };
        default:
            return state;
    }
}

export default commissionReducer;