import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import CustomFileInputAuth from '../info/CustomFileInputAuth';
//icons
import CancelIcon from '@material-ui/icons/Cancel';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// Context
import AuthContext from '../../context/auth/authContext';
import PaymentContext from '../../context/payment/paymentContext';

//Langs
import { useTranslation } from 'react-i18next';



const MakePay = (props) => {
    const { t } = useTranslation();

    const { user } = useContext(AuthContext)
    const { transferPayment } = useContext(PaymentContext)
    
    const [open, setOpen] = useState(props.open);
    const [paymentId] = useState(props.id);
    const [file, setFile] = useState(props.id);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    function handleSubmit(ev) {
        ev.preventDefault();
        transferPayment(paymentId, file);
        setOpen(false);
    }

    const handleFile = (file) => {
        console.log(file);
        setFile(file);
    }

    return (
        <div>
            <Button 
                variant="contained"
                color="primary"
                startIcon={props.startIcon}
                style={props.styleButton}
                onClick={handleClickOpen}>
                {props.text}
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">{t('payments.modalPay.title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {(user.hasAccounts) ? 
                                <div>
                                    <p>
                                        {t('payments.modalPay.context')} <br />
                                        <b>{t('payments.modalPay.account')}:</b> {user.userEmail}  <br />
                                        <b>{t('payments.modalPay.amount')}:</b> $ {props.amount} USD <br />
                                    </p>
                                    <CustomFileInputAuth 
                                        setMethod={handleFile} 
                                        icon={ <AddCircleOutlineIcon/> } 
                                        placeholder={file.name ?? t('payments.select.invoice')}
                                        accept='application/pdf'
                                    />
                                </div>
                            : 
                                t('payments.modalPay.needPay')
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button startIcon={ <CancelIcon/> } onClick={handleClose} style={{float: 'left'}} color="primary">
                            {t('payments.modalPay.buttons.cancel')}
                        </Button>
                        {(user.hasAccounts) ? 
                            <Button startIcon={ <ExitToAppIcon/> } color="primary" type="submit" disabled={!file.name}>
                                {t('payments.modalPay.buttons.accept')}
                            </Button>
                        : 
                            ``
                        }
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default MakePay;
