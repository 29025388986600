import Hidden from '@material-ui/core/Hidden';
import React from 'react';

import AboutUs from './components/Aboutus';
import Benefits from './components/Benefits';
import Clients from './components/ClientsLanding';
import Footer from './components/Footer';
import Join from './components/Join';
import MainSection from './components/MainSection';
import Review from './components/Review';
import NavBar from './navbar/NavBar';
import NavBarMobile from './navbar/NavBarMobile';
import './Landing.css';

function Landing () {
    
    return (
        <div>
            <div style={{overflow: 'hidden'}}>
                <Hidden only={['sm', 'xs', 'md']}>
                    <NavBar/>
                </Hidden>
                <Hidden only={['xl', 'lg']}>
                    <NavBarMobile/>
                </Hidden>
                <MainSection/>
                <Benefits/>
                <Clients/>
                <AboutUs/>
                <Review/>
                <Join/>
                <Footer/>
            </div>
        </div>
	)
}

export default Landing
