import React, {useReducer, useContext} from 'react';
import PaymentContext from './paymentContext';
import PaymentReducer from './paymentReducer'
import AuthContext from '../../context/auth/authContext';

import axiosClient from '../../config/axios';

import {
    GET_PARTNER_PAYMENTS,
    GET_PARTNER_PAYMENT_DETAILS,
    UPDATE_ECARTPAY_EMAIL,
    UPDATE_ECARTPAY_EMAIL_ERROR,
    TRANSFER_PAYMENT,
    TRANSFER_ERROR
} from '../../types'

const PaymentState = props => {

    const { user } = useContext(AuthContext)

    const initialState = {
        payments: [],
        commissions: [],
        transfer_response: '',
        update_email_response: '',
        message: '',
    }

    const [ state, dispatch ] = useReducer(PaymentReducer, initialState);

    const getPartnerPayments = (year) => {
        try {
            axiosClient({
                method: 'GET',
                url:`/get-payments?year=${year}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            }).then(response => {
                dispatch({
                    type:GET_PARTNER_PAYMENTS,
                    payload:response.data
                })
            })
            .catch(error => {
                console.log(error);
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    const getPartnerPaymentDetails = (payment_id) => {
        try {
            axiosClient({
                method: 'GET',
                url:`/get-payment-details?payment_id=${payment_id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            }).then(response => {
                dispatch({
                    type:GET_PARTNER_PAYMENT_DETAILS,
                    payload:response.data
                })
            })
            .catch(error => {
                console.log(error);
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    const transferPayment = (payment_id, file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            axiosClient({
                method: 'PUT',
                url:`/make-transfer?payment_id=${payment_id}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user.token}`,
                },
                data: formData
            }).then(response => {
                dispatch({
                    type: TRANSFER_PAYMENT,
                    payload:response.message,
                    message: 'Successfully transfer.'
                })
            })
            .catch(error => {
                dispatch({
                    type:TRANSFER_ERROR,
                    payload:error.response.data.message
                })
            });
            
        } catch (error) {
            dispatch({
                type:TRANSFER_ERROR,
                payload:'Ops! something was wring, try again later.'
            })
        }
    }

    const updateEcartPayEmail = (email) => {
        try {
            axiosClient({
                method: 'POST',
                url:`/payments/email`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                data: {
                    email
                }
            }).then(response => {
                dispatch({
                    type: UPDATE_ECARTPAY_EMAIL,
                    payload:response.message,
                    message: 'Successfully EcartPay email register.'
                })
            })
            .catch(error => {
                dispatch({
                    type:UPDATE_ECARTPAY_EMAIL_ERROR,
                    payload:error.response.data.message
                })
            });
            
        } catch (error) {
            dispatch({
                type:TRANSFER_ERROR,
                payload:'Ops! something was wring, try again later.'
            })
        }
    }

    return ( 
        <PaymentContext.Provider
            value={{
                payments: state.payments,
                commissions: state.commissions,
                transfer_response: state.transfer_response,
                update_email_response: state.update_email_response,
                message: state.message,
                getPartnerPayments,
                getPartnerPaymentDetails,
                transferPayment,
                updateEcartPayEmail,
                dispatch
            }}
        >
            {props.children}
        </PaymentContext.Provider>
    );
}
 
export default PaymentState;