
import {
    LOGIN,
    ERROR_LOGIN,
    LOGOUT,
    GET_LANGUAGES,
    RUNNING
} from '../../types'

const authReducer =  (state, action) => {
    switch (action.type) {

        case LOGIN:
            return {
                ...state,
                user: action.payload,
                autentication: true,
                logged: true,
                running: false,
            };
        case RUNNING:
            return {
                ...state,
                running: false,
            };
        case ERROR_LOGIN:
            localStorage.removeItem('token')
            return{
                ...state,
                error: action.payload,
                autentication: false,
                user: null
            };
        case LOGOUT:
            return {
                ...state,
                logged: false,
                user: null,
                autentication: false,
            };
        case GET_LANGUAGES:
            return {
                ...state,
                languages: action.payload
            };
        default:
            return {
                ...state,
                lang: action.payload
            };
    }
}

export default authReducer;