import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    mainSection:{
        paddingTop: '80.36px',
        background: `#ccebee`,
        [theme.breakpoints.down('md')]:{
            paddingTop:'0px',
        }
    },
    container: {
        [theme.breakpoints.up(1279)]: {
            maxWidth: 1140,
        },
        [theme.breakpoints.up(1600)]: {
            maxWidth: 1500,
        },
    },
    content:{
        textAlign:'left',
        paddingTop: '0px',
        paddingBottom: '0px',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]:{
            textAlign:'center',
            paddingTop: '65px',
            paddingBottom: '45px',
            alignItems: 'center',
        },
    },
    title: {
        fontSize: '2.6rem',
        fontWeight: 800,
        color: '#050629',
        marginBottom: 0,
        textTransform: 'uppercase',
    },
    logo: {
        width: 450,
        maxWidth: '100%',
        marginBottom: 15,
        [theme.breakpoints.down('lg')]: {
            marginBottom: 20,
        },
    },
    text: {
        color: '#00abec',
        width: '100%',
        maxWidth: 530,
        [theme.breakpoints.down('lg')]: {
            marginBottom: 20,
        },
    },
    heroContainer: {
        marginLeft: 'auto',
    },
    hero: {
        display: 'block',
        marginTop: 'auto',
        width: '100%',
    },
}));

function MainSection() {
    const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={classes.mainSection}>
            <Container className={classes.container}>
                <Grid container alignItems="center">
                    <Grid item xs={12} lg={6}>
                        <Box className={classes.content}>
                            <Typography className={classes.title} gutterBottom variant="h1">
                                {t('landing.section1.title')}
                            </Typography>
                            <img className={classes.logo} alt={'Partners'} src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Logo-Partners.png`} />
                            <Typography className={classes.text} gutterBottom variant="h6">
                                <Box fontWeight="fontWeightRegular">
                                    {t('landing.section1.text')}
                                </Box>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={5} className={classes.heroContainer}>
                        <img className={classes.hero} alt="Hero Partners" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Imagen-Partner.png`} />
                    </Grid>
                </Grid>
            </Container>
    	</div>
	);
}

export default  withRouter(MainSection);
