import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//Langs
import { useTranslation } from 'react-i18next';

// Material-UI
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

//Icons
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';

// Component
import Menu from '../../components/layouts/Menu';
import CustomInputFrom from '../../components/info/CustomInputForm';

//Context
import ClientContext from '../../context/client/clientContext';
import AuthContext from '../../context/auth/authContext';


const Profile = () => {
    const { t, i18n } = useTranslation();

    const { languages, getLangs } = useContext(ClientContext)
    const { user, lang, dispatch } = useContext(AuthContext)

    const classes = useStyles();
    const history = useHistory();
    const [flag, setflag] = useState(true);

    useEffect(() => {
        getLangs();
    }, []);

    const changeLanguageHandler = (lng) =>{
      i18n.changeLanguage(lng)
      dispatch({payload: lng});
    }

    const renderListLanguage = (language) => {
        return(
            <Paper className={classes.paperLanguage}>
                <Box style={{display: 'flex', alignItems: 'center'}}>
                    <img 
                        src={`${process.env.REACT_APP_S3_PATH}/uploads/locales/${language.id.slice(3,5)}.png`} 
                        alt="Country" 
                        className={classes.localeImage}
                    />
                    <Typography variant="h6" style={{paddingLeft: 20, width: '80%'}}>{language.description}</Typography>
                    {(lang == language.id) ? <Star  style={{width: '10%'}}/> : <StarBorder onClick={ (data) => changeLanguageHandler(language.id) } style={{width: '10%'}}/>}
                </Box>
            </Paper>
        )
    }

    return (
        <Menu menuCurrent={'Profile'}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h6">{t('profile.companyDetails')}</Typography>
                    <Typography variant="body2">{t('profile.companyDetailsContext')}</Typography>
                </Grid>

                <Grid item xs={12} sm={8}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={3} style={{display: 'flex', justifyContent: 'center'}}>
                                <Avatar variant="rounded"  alt="Remy Sharp" className={classes.large}/>
                            </Grid>
                            <Grid item xs={12} sm={9} style={{display: 'flex', justifyContent: 'center'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={9} style={{display: 'flex', justifyContent: 'center'}}>
                                        <CustomInputFrom
                                            type={'text'}
                                            disabled={true}
                                            label={t('profile.form.companyName')}
                                            placeholder={'Username'}
                                            value={user.companyName}/>
                                    </Grid>

                                    <Grid item xs={12} sm={3} style={{display: 'flex', justifyContent: 'center'}}>
                                        <CustomInputFrom
                                            type={'text'}
                                            disabled={true}
                                            label={t('profile.form.companyId')}
                                            placeholder={'Username'}
                                            value={user.companyId}/>
                                    </Grid>

                                    <Grid item xs={12} sm={8} style={{display: 'flex', justifyContent: 'center'}}>
                                        <CustomInputFrom
                                            type={'text'}
                                            disabled={true}
                                            label={t('profile.form.companyEmail')}
                                            placeholder={'Username'}
                                            value={user.userEmail}/>
                                    </Grid>

                                    <Grid item xs={12} sm={4} style={{display: 'flex', justifyContent: 'center'}}>
                                        <CustomInputFrom
                                            type={'text'}
                                            disabled={true}
                                            label={t('profile.form.companyPhone')}
                                            placeholder={'Username'}
                                            value={user.userPhone}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Divider variant="middle"/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h6">{t('profile.languages')}</Typography>
                    <Typography variant="body2">{t('profile.languagesContext')}</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    {languages.map( language => renderListLanguage(language) )}
                </Grid>
            </Grid>
        </Menu>
    )
}



const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20,
    },
    paperLanguage: {
        padding: 20,
        marginTop: 10,
        cursor: 'pointer'
    },
    large: {
        width: theme.spacing(14),
        height: theme.spacing(14),
    },
    localeImage: {
        height: '30px',
        width: '30px',
    },
}));

export default Profile;