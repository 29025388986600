import React, {useReducer, useContext} from 'react';
import DashboardContext from './dashboardContext';
import DashboardReducer from './dashboardReducer'
import AuthContext from '../../context/auth/authContext';

import axiosClient from '../../config/axios';

import {
    GET_PARTNER_REGISTERED_CLIENTS,
    GET_PARTNER_CLIENTS_SHIPMENTS,
    GET_PARTNER_AVG_COMMISSION,
} from '../../types'

const DashboardState = props => {

    const { user } = useContext(AuthContext)

    const initialState = {
        registers: [],
        shipments: [],
        commission: {},
    }

    const [ state, dispatch ] = useReducer(DashboardReducer, initialState);

    const getPartnerRegisteredClients = (year) => {
        try {
            axiosClient({
                method: 'GET',
                url:`/get-registered-clients?year=${year}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            }).then(response => {
                dispatch({
                    type:GET_PARTNER_REGISTERED_CLIENTS,
                    payload:response.data
                })
            })
            .catch(error => {
                console.log(error);
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    const getPartnerClientsShipments = (year) => {
        try {
            axiosClient({
                method: 'GET',
                url:`/get-clients-shipments?year=${year}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            }).then(response => {
                dispatch({
                    type:GET_PARTNER_CLIENTS_SHIPMENTS,
                    payload:response.data
                })
            })
            .catch(error => {
                console.log(error);
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    const getPartnerAvgCommission = (year) => {
        try {
            axiosClient({
                method: 'GET',
                url:`/get-avg-commission?year=${year}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            }).then(response => {
                dispatch({
                    type:GET_PARTNER_AVG_COMMISSION,
                    payload:response.data
                })
            })
            .catch(error => {
                console.log(error);
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    return ( 
        <DashboardContext.Provider
            value={{
                registers: state.registers,
                shipments: state.shipments,
                commission: state.commission,
                getPartnerRegisteredClients,
                getPartnerClientsShipments,
                getPartnerAvgCommission
            }}
        >
            {props.children}
        </DashboardContext.Provider>
    );
}
 
export default DashboardState;