import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

//Langs
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#0085c7',
        color: theme.palette.common.white,
        borderLeftColor: 'white',
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
        textAlign: 'center',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


const CustomTable = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {props.columns.map((column) => (
                            <StyledTableCell
                            key={column.id.toString()}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            >
                            {column.label}
                            </StyledTableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            {props.columns.map((column) => {
                                const value = row[column.id];
                                var complement = null;
                                (column.hasOwnProperty('complement')) ? complement = row[column.complement] : complement = null;
                                return (
                                    <TableCell key={column.id.toString()} align={column.align} >
                                        {
                                            (!complement) ?
                                                column.hasOwnProperty('view') 
                                                ? column.view(column.format && typeof value === 'number' ? column.format(value) : value) 
                                                : column.format && typeof value === 'number' ? column.format(value) : value
                                            :
                                                column.hasOwnProperty('view') 
                                                    ? column.view({value: column.format && typeof value === 'number' ? column.format(value) : value, complement}) 
                                                    : column.format && typeof value === 'number' ? column.format(value) : value
                                        }
                                    </TableCell>
                                );
                            })}
                            </TableRow>
                        );
                        })}
                        {props.rows.length <= 0 && (
                            <TableRow>
                                <TableCell colSpan={7}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <SearchIcon fontSize="large" color="disabled"/>
                                        <span color="disabled">{t('general.table.noRecords')}</span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    )
}

export default CustomTable;