import React, {useReducer, useContext} from 'react';
import CommisssionContext from './commissionContext';
import CommissionReducer from './commissionReducer'
import AuthContext from '../../context/auth/authContext';

import axiosClient from '../../config/axios';

import {
    GET_PARTNER_COMMISSIONS,
} from '../../types'

const CommissionState = props => {

    const { user } = useContext(AuthContext)

    const initialState = {
        commissions: [],
    }

    const [ state, dispatch ] = useReducer(CommissionReducer, initialState);

    const getPartnerCommissions = (carrier) => {
        try {
            axiosClient({
                method: 'GET',
                url:`/get-commission?carrier=${carrier}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            }).then(response => {
                dispatch({
                    type:GET_PARTNER_COMMISSIONS,
                    payload:response.data
                })
            })
            .catch(error => {
                console.log(error);
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    return ( 
        <CommisssionContext.Provider
            value={{
                commissions: state.commissions,
                getPartnerCommissions,
            }}
        >
            {props.children}
        </CommisssionContext.Provider>
    );
}
 
export default CommissionState;