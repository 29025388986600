import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';




const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 30,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        background: '#184d9f',
        color: 'white'
    },
    divider: {
        height: 28,
        margin: 4,
    },
    labelText: {
        marginLeft: 20,
    }
}));

const InputDateIcon = (props) => {
    const classes = useStyles();
    return (
        <div>
            <Typography className={classes.labelText} aria-controls="long-menu">{props.labelText}</Typography>
            <Paper component="form" className={classes.root}>
                <InputBase
                    onClick={props.onClick}
                    className={classes.input}
                    placeholder={props.placeholder}
                    value={props.value}
                />
                <IconButton className={classes.iconButton} onClick={props.onClick}>
                    {props.icon}
                </IconButton>
            </Paper>
        </div>
    )
}

export default InputDateIcon;