import { Box, Button } from '@material-ui/core';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import LanguageSelector from '../components/LanguageSelector';

const useStyles = makeStyles((theme) => ({
    navbar: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 5,
        background: '#000b2b',
    },
    container: {
        [theme.breakpoints.up(1600)]: {
            maxWidth: 1500,
        },
    },
    navContainer:{
        display: 'flex',
    },
    nav: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
    },
    logoPartners: {
        maxWidth: '40px',
    },
    navButton: {
        color: '#ffffff',
        background: '#01a8f1',
        borderRadius: 0,
        padding: '5px 32px',
        fontSize: '1rem',
        marginRight: '15px',
        fontWeight: '400',
        '&:hover': {
            background: '#2cb3ec',
            color: 'white',
        },
    },
    languageSelectorContainer: {
        marginLeft: '15px',
        width: 130,
    },
}));

function NavBar () {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <nav className={classes.navbar}>
            <Container className={classes.container}>
                <Box className={classes.navContainer} p={1} py={2}>
                    <Box>
                        <a href="/">
                            <img src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Favicon.png`} alt="Partners logo" className={classes.logoPartners} />
                        </a>
                    </Box>
                    <Box className={clsx(classes.nav)}>
                        <Button className={classes.navButton} href="/login">
                            {t('auth.titleFormLogin')}
                        </Button>
                        <Button className={classes.navButton} href="/signup">
                            {t('auth.titleFormRegister')}
                        </Button>
                        <a href="https://envia.com/">
                            <img alt="Envia logo" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/countries/MEX/white-logo.svg`} />
                        </a>
                        <div className={classes.languageSelectorContainer}>
                            <LanguageSelector variant="light" />
                        </div>
                    </Box>
                </Box>
            </Container>
        </nav>
    )
}

export default withRouter(NavBar)