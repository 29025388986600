import React, { useContext, useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';

// Material-UI
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';

// Files

//Langs
import { useTranslation } from 'react-i18next';

//Icons
import SearchIcon from '@material-ui/icons/Search';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Component
import Menu from '../../components/layouts/Menu';
import InputIcon from '../../components/info/InputIcon';
import CustomTable from '../../components/info/CustomTable';
import InputDateIcon from '../../components/info/InputDateIcon';
//Context
import ClientContext from '../../context/client/clientContext'

//Libraries
import DatePicker from "react-datepicker";

//Css
import "react-datepicker/dist/react-datepicker.css";



const InfoClient = () => {
    const { t } = useTranslation();

    const classes = useStyles();
    const { id } = useParams();

    const { client, clientCommissions, getInfoClient, getClientCommission} = useContext(ClientContext);
    const [startDate, setStartDate] = useState(new Date());
    const [carrier, setCarrier] = useState('');
    const [shipments, setShipments] = useState(0);
    const [commission, setCommission] = useState(0);
    var timeout;
    
    useEffect(() => {
        getInfoClient(id);
    }, []);

    useEffect(() => {
        const filters = {
            company: id,
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1,
            carrier
        };
        getClientCommission(filters);
    }, [startDate, carrier]);

    useEffect(() => {
        let sh = 0;
        let comm = 0;
        clientCommissions.map((row) => {
            sh += row.shipments;
            comm += parseFloat(row.commission);
        });
        setShipments(sh);
        setCommission((comm) ? comm.toFixed(2) : 0);
    }, [clientCommissions]);
    
    function handleTiping(event){
        clearTimeout(timeout);
        timeout = setTimeout(function(){
            clearTimeout(timeout);
            setCarrier(event.target.value)
        }, 2000);
    }

    const columns = [
        { id: 'carrier_description', label: 'Paqueteria', view: (value) => {
            return (
                <Typography style={{textAlign: 'center'}}>{value}</Typography>
            )
        }},
        { id: 'shipments', label: 'Total Envios', view: (value) => {
            return (
                <Typography style={{textAlign: 'center' ,color: '#184d9f', fontWeight: 'bold'}}>{value}</Typography>
            )
        }},
        { id: 'percentage', label: 'Comisión', view: (value) => {
            return (
                <Typography style={{textAlign: 'center' }}> {value}% </Typography>
            )
        }},
        { id: 'commission', label: 'Recompensa', view: (value) => {
            return (
                <Typography style={{textAlign: 'center' }}> ${value} USD </Typography>
            )
        }},
    ];

    return (
        <Menu menuCurrent={'Clients'}>
            <Grid container spacing={3}>
                <Box style={{marginTop: 20, marginBottom: 20}}>
                    <Link to="/clients"><Typography variant="body1" className={classes.return}> <ArrowBackIcon/> {t('clients.info.return')}</Typography></Link>
                </Box>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={2} className={classes.avatarContent}>
                                <img 
                                    src={`${process.env.REACT_APP_S3_PATH}/uploads/locales/${client.country_code}.png`} 
                                    alt="Country" 
                                    className={classes.localeImage}
                                />
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <Typography variant="h5" className={classes.companyName}>{t('clients.info.company')}: {client.company_name}</Typography>
                                <Typography variant="subtitle1" className={classes.companyId}>#{id}</Typography>
                            </Grid>
                            <Grid item xs={12} md={8} style={{alignSelf: 'center'}}>
                                <Box className={classes.contentInfoClient}>
                                    <Typography variant="body2" className={classes.infoClient} style={{textAlign: 'right', paddingRight: 10}}>{t('clients.info.register')}:</Typography>
                                    <Typography variant="body2" className={classes.infoClient}>{client.created_at}</Typography>
                                </Box>
                                <Box className={classes.contentInfoClient}>
                                    <Typography variant="body2" className={classes.infoClient} style={{textAlign: 'right', paddingRight: 10}}>{t('clients.info.lastShipment')}:</Typography>
                                    <Typography variant="body2" className={classes.infoClient}>{client.last_shipment}</Typography>
                                </Box>
                                <Box className={classes.contentInfoClient}>
                                    <Typography variant="body2" className={classes.infoClient} style={{textAlign: 'right', paddingRight: 10}}>{t('clients.info.timeClient')}:</Typography>
                                    <Typography variant="body2" className={classes.infoClient}>{client.creation} {t('clients.info.months')}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Paper className={classes.paperChart}>
                        <Typography className={classes.titleChart}>{t('clients.info.totalShipment')}</Typography>
                        <Typography className={classes.countChart} style={{color: '#1A499F'}}>{shipments}</Typography>
                        <Typography className={classes.contentTextChart}>{t('clients.info.totalShipment.context')}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Paper className={classes.paperChart}>
                        <Typography className={classes.titleChart}>{t('clients.info.commission')}</Typography>
                        <Typography className={classes.countChart} style={{color: '#FF8852'}}>$ {commission} USD</Typography>
                        <Typography className={classes.contentTextChart}>{t('clients.info.commission.context')}</Typography>
                    </Paper>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                        <InputIcon onChange={ handleTiping } placeholder={t('clients.form.placeholder.searchHere')} icon={<SearchIcon/>} labelText={'Carrier'}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <DatePicker 
                            selected={startDate} 
                            onChange={(date) => setStartDate(date)} 
                            customInput={<InputDateIcon placeholder={t('clients.form.placeholder.searchHere')} icon={<DateRangeIcon/>} labelText={'Date'}/>}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                        />
                    </Grid>
                    <Grid item xs={12} style={{width: 100}}>
                        <CustomTable rows={clientCommissions} columns={columns}/>
                    </Grid>
                </Grid>
            </Grid>
        </Menu>
    )
}


const useStyles = makeStyles((theme) => ({
    return: {
        display: 'flex',
        alignItems: 'center',
        color: '#1F3592',
        fontWeight: 'bold',
        fontStyle: 'none'
    },
    title: {
        fontWeight: 'bold',
    },
    paper: {
        padding: 20,
        borderRadius: 20,
        height: '100%',
    },
    paperChart: {
        padding: 20,
        borderRadius: 20,
        height: '100%',
    },
    avatarContent: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center'
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    companyName: {
        color: '#1F3592',
    },
    contentInfoClient: {
        display: 'flex',
    },
    infoClient: {
        width: '50%',
    },
    titleChart: {
        textAlign: 'center',
        fontSize: '1.4em',
    },
    countChart: {
        fontSize: '2.5em',
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 20,
    },
    contentTextChart: {
        marginTop: 20,
        fontSize: 12,
        textAlign: 'center',
        height: 30,
    },
    localeImage: {
        height: '55px',
        width: '55px',
    },
}));

export default InfoClient;