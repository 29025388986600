import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import AuthContext from '../context/auth/authContext';
import Spinner from '../components/Spinner/Index';

const PrivateRoutes = ({
    isAuthenticated,
    component: Component,
    ...rest
}) => {
    const { logged, running } = useContext(AuthContext);
    return (
        <Route {...rest}
            component={ (props) => (
                (running) 
                    ? <Spinner />
                    : (logged)
                        ? <Component { ...props } />
                        : <Redirect to="/login" />
            )}
        
        />
    )
}

PrivateRoutes.prototype = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}

export default PrivateRoutes;