import React, { useContext } from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';

//Page Primary
import Landing from '../page/landing/Landing';
import Dashboard from '../page/dashboard/Dashboard';
import Clients from '../page/client/Clients';
import AddStore from '../page/client/AddStore';
import Commission from '../page/commission/Commission';
import Payments from '../page/payments/Payments';
import Team from '../page/team/Team';
import Support from '../page/support/Support';
import Profile from '../page/profile/Profile';
import Language from '../page/profile/Language';

// Page Secondary
import InfoClient from '../page/client/InfoClient';

// Safe Routes
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

// Contexts
import AuthContext from '../context/auth/authContext';

const Routes = () => {

    const { logged } = useContext(AuthContext);

    return (

        <Router>
            <Switch>
                <PublicRoutes exact path="/" component={Landing} isAuthenticated={logged}/>
                <PrivateRoutes exact path="/dashboard" component={Dashboard} isAuthenticated={logged}/>
                <PrivateRoutes exact path="/clients" component={Clients} isAuthenticated={logged}/>
                <PrivateRoutes exact path="/prospects" component={AddStore} isAuthenticated={logged}/>
                <PrivateRoutes exact path="/clients/:id" component={InfoClient} isAuthenticated={logged}/>
                <PrivateRoutes exact path="/commission" component={Commission} isAuthenticated={logged}/>
                <PrivateRoutes exact path="/payments" component={Payments} isAuthenticated={logged}/>
                <PrivateRoutes exact path="/team" component={Team} isAuthenticated={logged}/>
                <PrivateRoutes exact path="/support" component={Support} isAuthenticated={logged}/>
                <PrivateRoutes exact path="/profile" component={Profile} isAuthenticated={logged}/>
                <PrivateRoutes exact path="/profile/language" component={Language} isAuthenticated={logged}/>
            </Switch>
        </Router>
    )
}

export default Routes;