import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles} from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import AuthContext from '../../../context/auth/authContext';

const useStyles = makeStyles(theme => ({
    clients: {
        backgroundColor: '#00122d',
        paddingTop: 80,
        paddingBottom: 80,
    },
    titleContainer: {
        textAlign: 'center',
        marginBottom: 50,
        textTransform: 'uppercase',
    },
    title: {
        fontSize: '2.6rem',
        fontWeight: 800,
        color: '#ffffff',
    },
    targetImage: {
        width: 450,
        maxWidth: '100%',
    },
    boxContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '300px',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '100%',
        },
    },
    list: {
        paddingTop: 0,
    },
    listTitle: {
        color: '#ffffff',
        fontSize: '1.6rem',
        marginBottom: 0,
        lineHeight: 1,
    },
    listSubtitle: {
        color: '#ffffff',
        fontWeight: 800,
        fontSize: '1.8rem',
        marginBottom: 0,
        lineHeight: 1.3,
    },
    listItem: {
        paddingLeft: 0,
        alignItems: 'flex-start',
    },
    listIcon: {
        minWidth: 'auto',
        marginRight: 5,
        marginTop: 5,
    },
    listText: {
        marginBottom: 0,
        marginTop: 0,
        color: '#68a3e5',
    },
    whiteStar: {
        maxWidth: 15,
    },
}));

function ClientsLanding() {
    const { lang } = useContext(AuthContext)
    const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={classes.clients}>
            <Container className="landing-container" maxWidth="lg" style={{maxWidth: '1500px'}}>
                <Grid container spacing={4} direction="row">
                    <Grid item xs={12} lg={12}>
                        <Box className={classes.titleContainer}>
                            <Typography className={classes.title} gutterBottom variant="h2" component="h2">
                                {t('landing.clients.title')}
                            </Typography>
                            <img
                                className={classes.targetImage}
                                alt="Clientes"
                                src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Envia-Partners-Animated-Text${lang === 'en-US' ? '-EN' : ''}.gif`}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={4} direction="row" justifyContent="center">
                    <Grid item xs={12} md={4} lg={3}>
                        <Box className={classes.boxContainer}>
                            <Typography className={classes.listTitle} variant="h5" component="h5">
                                &nbsp;
                            </Typography>
                            <Typography className={classes.listSubtitle} variant="h6" component="h6">
                                {t('landing.clients.card1.subtitle')}
                            </Typography>
                            <List className={classes.list}>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listIcon}>
                                        <img className={classes.whiteStar} alt="White star" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-Blanco-.png`} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.listText}>
                                        {t('landing.clients.card1.sentence1')}
                                    </ListItemText>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listIcon}>
                                        <img className={classes.whiteStar} alt="White star" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-Blanco-.png`} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.listText}>
                                        {t('landing.clients.card1.sentence2')}
                                    </ListItemText>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listIcon}>
                                        <img className={classes.whiteStar} alt="White star" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-Blanco-.png`} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.listText}>
                                        {t('landing.clients.card1.sentence3')}
                                    </ListItemText>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listIcon}>
                                        <img className={classes.whiteStar} alt="White star" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-Blanco-.png`} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.listText}>
                                        {t('landing.clients.card1.sentence4')}
                                    </ListItemText>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listIcon}>
                                        <img className={classes.whiteStar} alt="White star" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-Blanco-.png`} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.listText}>
                                        {t('landing.clients.card1.sentence5')}
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Box className={classes.boxContainer}>
                            <Typography className={classes.listTitle} variant="h5" component="h5">
                                {t('landing.clients.card2.title')}
                            </Typography>
                            <Typography className={classes.listSubtitle} variant="h6" component="h6">
                                {t('landing.clients.card1.subtitle')}
                            </Typography>
                            <List className={classes.list}>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listIcon}>
                                        <img className={classes.whiteStar} alt="White star" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-Blanco-.png`} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.listText}>
                                        {t('landing.clients.card2.sentence1')}
                                    </ListItemText>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listIcon}>
                                        <img className={classes.whiteStar} alt="White star" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-Blanco-.png`} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.listText}>
                                        {t('landing.clients.card1.sentence4')}
                                    </ListItemText>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listIcon}>
                                        <img className={classes.whiteStar} alt="White star" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-Blanco-.png`} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.listText}>
                                        {t('landing.clients.card1.sentence5')}
                                    </ListItemText>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listIcon}>
                                        <img className={classes.whiteStar} alt="White star" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-Blanco-.png`} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.listText}>
                                        {t('landing.clients.card1.sentence6')}
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
    	</div>
	);
}

export default withRouter(ClientsLanding);
