import {
    GET_PARTNER_REGISTERED_CLIENTS,
    GET_PARTNER_CLIENTS_SHIPMENTS,
    GET_PARTNER_AVG_COMMISSION,
} from '../../types'

const dashboardReducer = (state, action) => {
    switch (action.type) {

        case GET_PARTNER_REGISTERED_CLIENTS:
            return{
                ...state,
                registers: action.payload
            };
        case GET_PARTNER_CLIENTS_SHIPMENTS:
            return{
                ...state,
                shipments: action.payload
            };
        case GET_PARTNER_AVG_COMMISSION:
            return{
                ...state,
                commission: action.payload
            };
        default:
            return state;
    }
}

export default dashboardReducer;