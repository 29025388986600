import {
    GET_PARTNER_PAYMENTS,
    GET_PARTNER_PAYMENT_DETAILS,
    UPDATE_ECARTPAY_EMAIL,
    UPDATE_ECARTPAY_EMAIL_ERROR,
    TRANSFER_PAYMENT,
    TRANSFER_ERROR
} from '../../types'

const paymentReducer = (state, action) => {
    switch (action.type) {

        case GET_PARTNER_PAYMENTS:
            return{
                ...state,
                payments: action.payload
            };
        case GET_PARTNER_PAYMENT_DETAILS:
            return{
                ...state,
                commissions: action.payload
            };
        case TRANSFER_PAYMENT:
            return{
                ...state,
                message: action.message,
                transfer_response: 'success',
            };
        case UPDATE_ECARTPAY_EMAIL:
            return{
                ...state,
                message: action.message,
                update_email_response: 'success',
            };
        case UPDATE_ECARTPAY_EMAIL_ERROR:
            return{
                ...state,
                message: action.payload,
                update_email_response: 'error',
            };
        case TRANSFER_ERROR:
            return{
                ...state,
                message: action.payload,
                transfer_response: 'error',
            };
        default:
            return{
                ...state,
                message: '',
                update_email_response: '',
                transfer_response: '',
            };
    }
}

export default paymentReducer;