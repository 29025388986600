import React from 'react';

// Material-UI
import Button from '@material-ui/core/Button';

const CustomButton = (props) => {
    return (
            <Button
                variant="contained"
                color="primary"
                startIcon={props.startIcon}
                
                style={props.styleButton}>

                {props.text}

            </Button>
    );
}

export default CustomButton;