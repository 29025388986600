import React, { useContext, useState, useEffect, memo } from 'react';
import { toast } from 'react-hot-toast';

//Langs
import { useTranslation } from 'react-i18next';

// Material-UI
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

//Icons
import DateRangeIcon from '@material-ui/icons/DateRange';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';

// Component
import Menu from '../../components/layouts/Menu';
import PaymentsTable from '../../components/info/PaymentsTable';
import InputDateIcon from '../../components/info/InputDateIcon';
import { useCookies } from 'react-cookie';
import CustomButton from '../../components/info/CustomButton';

//Context
import PaymentContext from '../../context/payment/paymentContext'
import AuthContext from '../../context/auth/authContext'
//Libraries
import DatePicker from "react-datepicker";

//Modals
import MakePay from '../../components/modals/MakePay';
import RegisterPay from '../../components/modals/RegisterPay';

//Css
import "react-datepicker/dist/react-datepicker.css";

import {
    CLEAN,
} from '../../types'

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold',
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 30,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        background: '#184d9f',
        color: 'white'
    },
    divider: {
        height: 28,
        margin: 4,
    },
    block: {
        display: 'inline-block'
    },
    blockMr: {
        display: 'inline-block',
        margin: '15px'
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Payments = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { user } = useContext(AuthContext)
    const { payments, getPartnerPayments, transfer_response, message , dispatch} = useContext(PaymentContext);
    const [startDate, setStartDate] = useState(new Date());
    const [openAlert, setOpenAlert] = useState(true)
    const [ , setCookie] = useCookies(['show_alert_register_pay']);

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        getPartnerPayments(startDate.getFullYear());
    }, [startDate]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
        setCookie('alertPayValidation', true)
    };

    useEffect(() => {
        if (transfer_response === 'success') {
            toast.success(message);
            getPartnerPayments(startDate.getFullYear());
        }
        
        if (transfer_response === 'error') {
            toast.error(message);
        }

        dispatch({
            type: CLEAN
        })

    }, [transfer_response]);

    const columns = [
        { id: 'id', label: t('payments.table.header.id'), view: (value) => {
            return (
                <Typography style={{textAlign: 'center' ,color: '#184d9f', fontWeight: 'bold'}}>{value}</Typography>
            )
        }},
        { id: 'reference', label: t('payments.table.header.reference'), view: (value) => {
            return (
                <Typography style={{textAlign: 'center' ,color: '#184d9f', fontWeight: 'bold'}}>{value}</Typography>
            )
        }},
        { id: 'amount', label: t('payments.table.header.commission'), view: (value) => {
            return (
                <Typography style={{textAlign: 'center' }}> ${value} USD</Typography>
            )
        }},
        { id: 'created_at', label: t('payments.table.header.createdAt'), view: (value) => {
            return (
                <Typography style={{textAlign: 'center'}}>{value}</Typography>
            )
        }},
        { id: 'paid_at', label: t('payments.table.header.confirmedAt'), view: (value) => {
            return (
                <Typography style={{textAlign: 'center'}}>{value}</Typography>
            )
        }},
        { id: 'status', all: true, align: 'center', label: t('payments.table.header.paymentStatus'), view: (el) => {
            if(!el.status || el.status === 0){
                return (
                    <MakePay text={t('payment.status.unpaid')} id={el.id} amount={el.amount} startIcon={<PaymentIcon />}/>
                )
            }else if (el.status === 1){
                return (
                    <Typography style={{textAlign: 'center', color: 'green'}}>{t('payment.status.paid')}</Typography>
                )
            } else if (el.status === 2){
                return (
                    <Typography style={{textAlign: 'center', color: '#ffc107'}}>{t('payment.status.processing')}</Typography>
                )
            }
        }}
    ];

    return (
        <Menu menuCurrent={'Payments'}>
            <RegisterPay open={openModal} callback={setOpenModal}/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {
                        openAlert && (
                            <Grid item xs={12}>
                                <Alert severity="warning" onClose={handleClose} style={{alignItems: 'center',}}>
                                    {t('payments.alert.message')}
                                    <Button startIcon={ <ExitToAppIcon/> } onClick={() => setOpenModal(true)} color="primary" type="button">
                                        {t('payments.alert.button.redirection')}
                                    </Button>
                                </Alert> 
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.title}>{t('menu.payments')}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <DatePicker 
                        selected={startDate} 
                        onChange={(date) => setStartDate(date)} 
                        customInput={<InputDateIcon placeholder={'Search here'} icon={<DateRangeIcon/>} labelText={t('payments.form.data')}/>}
                        dateFormat="yyyy"
                        showYearPicker
                    />
                </Grid>
                {(user.hasAccounts) ?
                    <Grid item xs={12} sm={9} style={{alignSelf: 'flex-end', marginBottom: 10, textAlign: 'end'}}>
                        <a 
                            target="_BLANK"
                            href={`${process.env.REACT_APP_ECART_PAY_API}`} rel="noreferrer">
                            <CustomButton startIcon={ <PaymentIcon/> } text={t('payments.button.redirectionPay')} styleButton={{borderRadius: 30, fontSize: 16}} />
                        </a>
                    </Grid>
                :``}
                <Grid item xs={12} style={{width: 100}}>
                    <PaymentsTable rows={payments} columns={columns}/>
                </Grid>
            </Grid>
        </Menu>
    )
}

export default memo(Payments);