
export const CLEAN = 'CLEAN';

//MENU
export const CHANGE_MENU = 'CHANGE_MENU';

//AUTH
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ERROR_LOGIN = 'ERROR_LOGIN';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const RUNNING = 'RUNNING';

//CLIENTS
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_INFO_CLIENT = 'GET_INFO_CLIENT';
export const GET_CLIENT_COMMISSIONS = 'GET_CLIENT_COMMISSIONS';
export const GET_CLIENT_PROSPECTS = 'GET_CLIENT_PROSPECTS';
export const REGISTER_PROSPECTS = 'REGISTER_PROSPECTS';
export const ERROR_PROSPECT = 'ERROR_PROSPECT';
export const GET_PHONE_CODES = 'GET_PHONE_CODES';

//COMMISSIONS
export const GET_PARTNER_COMMISSIONS = 'GET_PARTNER_COMMISSIONS';

//PAYMENTS
export const GET_PARTNER_PAYMENTS = 'GET_PARTNER_PAYMENTS';
export const GET_PARTNER_PAYMENT_DETAILS = 'GET_PARTNER_PAYMENT_DETAILS';
export const TRANSFER_PAYMENT = 'TRANSFER_PAYMENT';
export const TRANSFER_ERROR = 'TRANSFER_ERROR';
export const UPDATE_ECARTPAY_EMAIL = 'UPDATE_ECARTPAY_EMAIL';
export const UPDATE_ECARTPAY_EMAIL_ERROR = 'UPDATE_ECARTPAY_EMAIL_ERROR';

//DASHBOARD
export const GET_PARTNER_REGISTERED_CLIENTS = 'GET_PARTNER_REGISTERED_CLIENTS';
export const GET_PARTNER_CLIENTS_SHIPMENTS = 'GET_PARTNER_CLIENTS_SHIPMENTS';
export const GET_PARTNER_AVG_COMMISSION = 'GET_PARTNER_AVG_COMMISSION';

//LANDING
export const GET_LANDING_LANGUAGES = 'GET_LANDING_LANGUAGES';
export const SET_LANDING_LANGUAGE = 'SET_LANDING_LANGUAGE';
