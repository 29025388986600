import React, { useRef } from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 10,
        backgroundColor: '#EEEDF2',
        cursor: 'pointer',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        color: '#184d9f'
    },
    labelText: {
        marginLeft: theme.spacing(1),
        flex: 1,
    }
}));


const CustomFileInputAuth = (props) => {
    const {setMethod, accept = 'image/png, .jpeg, .jpg'} = props;
    const classes = useStyles();
    const fileInput = useRef(null)

    const onChangeFile = (e) => {
        console.log(e.target.files[0]);
        setMethod(e.target.files[0]);
    }
    
    return(
        <div className={classes.root} style={props.contentStyles} onClick={() => fileInput.current.click()}>
            <IconButton className={classes.iconButton}>
                {props.icon}
            </IconButton>
            <Typography className={classes.labelText}>{props.placeholder}</Typography>
            <input
                type='file'
                accept={accept}
                ref={fileInput}
                onChange={onChangeFile}
                style={{ display: 'none' }}
            />
        </div>
    )
}

export default CustomFileInputAuth;