import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { Language } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import LanguageSelector from '../components/LanguageSelector';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor:'red'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: '#000b2b',
    },
    menuButton: {
        marginRight: theme.spacing(-2),
        marginLeft: 'auto',
        [theme.breakpoints.up('lg')]: {
        display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    closeMenuButton: {
        marginLeft: 'auto',
        marginRight: 0,
    },
    ImageMenu: {
        marginRight: 'auto',
        marginLeft: 0,
        width: '20%',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    logoImage: {
        maxWidth: '40px',
    },
    logoImagewms: {
        marginLeft: theme.spacing(-2),
        width: '83px',
        height: '42px',
        position: 'absolute',
    }
}));

function NavBarMobile (props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    };
    const drawer = (
        <div>
            <List>
                <Divider/>
                <ListItem button component={Link} to="/side-login">
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('auth.titleFormLogin')} />
                </ListItem>
                <Divider/>
                <ListItem>
                    <ListItemIcon>
                        <Language />
                    </ListItemIcon>
                    <ListItemText>
                        <LanguageSelector />
                    </ListItemText>
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                    <MenuIcon />
                    </IconButton>
                    <Button component={Link} to="/" style={{width: '160px', maxWidth: '160px'}} className={clsx("", classes.logoImagewms)}>
                        <img alt="Partners logo" className={classes.logoImage} src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Favicon.png`} />
                    </Button>
                </Toolbar>
            </AppBar>
            
            <nav id="scroll-bar" className={classes.drawer}>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                            <CloseIcon/>
                        </IconButton>
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <div className={classes.content}>
                <div className={classes.toolbar} />
            </div>
        </div>
    );
}

NavBarMobile.propTypes = {
    container: PropTypes.object,
}

export default withRouter(NavBarMobile)