import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';



const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 30,
        height: 50
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        background: '#184d9f',
        color: 'white',
    },
    text: {
        width: '100%',
        paddingLeft: 10,
        cursor: 'pointer',
    },
    labelText: {
        marginLeft: 20,
    }
}));


const ITEM_HEIGHT = 48;

const SelectIcon = (props) => {
    const classes = useStyles();

    const [dataSelect, setDataSelect] = React.useState(props.data[0].label);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleChangeSelect = (option) => {
        setDataSelect(option)
        setAnchorEl(null);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div>
            <Typography className={classes.labelText} onClick={handleOpen}>{props.labelText}</Typography>
            <Paper component="form" className={classes.root}>
                
                <Typography className={classes.text} onClick={handleOpen} >{dataSelect}</Typography>

                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    onClick={props.onClick}
                    PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                    }}
                >
                    {props.data.map((option) => (
                    <MenuItem key={option.label} data-value={option.val} selected={dataSelect === option.label} onClick={() => handleChangeSelect(option.label)}>
                        {option.label}
                    </MenuItem>
                    ))}
                </Menu>
                {
                    props.icon && (
                        <IconButton className={classes.iconButton}>
                            {props.icon}
                        </IconButton>
                    )
                }
            </Paper>
        </div>
    )
}

export default SelectIcon;