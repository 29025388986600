import React from 'react';

// Material-UI
import { 
    fade,
    withStyles,
    makeStyles,
 } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';




const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%'
    }
}));

const InputIconAuth = (props) => {
    const classes = useStyles();


    return (
        <FormControl className={classes.content}>
            <InputLabel shrink htmlFor="bootstrap-input">
                {props.label}
            </InputLabel>
            <CustomInput
                disabled={props.disabled || false}
                type={props.type}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value || ''}
                onChange={props.onChange}
            />
        </FormControl>
    )
}



const CustomInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(2),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#EEEDF2',
        fontSize: 16,
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase);

export default InputIconAuth;