import { Container, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import quote from '../../../assets/images/quote.png';

const useStyles = makeStyles(theme => ({
    review: {
        background: '#ffffff',
        paddingBottom: 80,
    },
    container: {
        [theme.breakpoints.up(1279)]: {
            maxWidth: 1140,
        },
        [theme.breakpoints.up(1600)]: {
            maxWidth: 1500,
        },
    },
    reviewContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    quote: {
        position: 'absolute',
        top: -25,
        left: 0,
        height: 50,
        width: 'auto',
    },
    reviewTextContainer: {
        [theme.breakpoints.down('sm')]: {
            order: 1,
        },
    },
    reviewText: {
        position: 'relative',
        color: '#00abec',
        borderTop: '3px solid #000000',
        borderBottom: '3px solid #000000',
        padding: '20px 20px 20px 60px',
        marginRight: 15,
    },
    clientData: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginRight: 'auto',
            marginLeft: 'auto',
            marginBottom: '30px',
        },
    },
    clientImage: {
        width: 200,
        height: 'auto',
    },
    clientName: {
        textAlign: 'center',
        fontWeight: 900,
        marginTop: -15,
        fontSize: '1.1rem',
    },
    starsContainer: {
        color: '#00abec',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

function Review() {
    const classes = useStyles();
    const { t } = useTranslation();

	return (
        <Box className={classes.review}>
            <Container className={classes.container}>
                <Grid container spacing={0} direction='row' justifyContent='center'>
                    <Grid item xs={12} md={9} lg={8} xl={7}>
                        <Box className={classes.reviewContainer}>
                            <Box className={classes.reviewTextContainer}>
                                <Typography gutterBottom variant="h6" className={classes.reviewText}>
                                    <img className={classes.quote} alt="Quote" src={quote}/>
                                    {t('landing.review1.text').replace(/’’|‘’/g, '')}
                                </Typography>
                            </Box>
                            <Box className={classes.clientData}>
                                <img className={classes.clientImage} alt="Roberto" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Roberto.png`} />
                                <Typography className={classes.clientName} variant="h6">
                                    {t('landing.review1.name')}
                                </Typography>
                                <Box className={classes.starsContainer}>
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
	);
}

export default  withRouter(Review);
