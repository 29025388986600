import React, {useContext, useEffect, useState} from 'react';

//Langs
import { useTranslation } from 'react-i18next';

// Material-UI
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

//Icons
import SearchIcon from '@material-ui/icons/Search';

// Component
import Menu from '../../components/layouts/Menu';
import InputIcon from '../../components/info/InputIcon';
import CustomTable from '../../components/info/CustomTable';

//Context
import CommissionContext from '../../context/commission/commissionContext'

// Files
import { Box, capitalize } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold',
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 30,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        background: '#184d9f',
        color: 'white'
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const Commission = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { commissions, getPartnerCommissions} = useContext(CommissionContext);
    const [carrier, setCarrier] = useState('');
    var timeout;

    useEffect(() => {
        getPartnerCommissions(carrier);
    }, [carrier]);

    function handleTiping(event){
        clearTimeout(timeout);
        timeout = setTimeout(function(){
            clearTimeout(timeout);
            getPartnerCommissions(event.target.value)
        }, 2000);
    }

    const columns = [
        { id: 'carrier_name', label: t('commission.table.carrier'), view: (value) => {
            return (
                <Box style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{textAlign: 'center', marginRight: 20}}>
                        <img src={`${process.env.REACT_APP_S3_PATH}/uploads/logos/carriers/${value}.svg`} width="50" alt="card"/>
                    </div>
                    <Typography style={{textAlign: 'center' ,color: '#184d9f', fontWeight: 'bold'}}>{capitalize(value)}</Typography>
                </Box>
            )
        }},
        { id: 'percentage', complement: 'isCustom', label: t('commission.table.percentage'), view: (el) => {
            return (
                <Typography style={{textAlign: 'center' }}> {el.value}% {(el.complement) ? t('commission.table.custom'): t('commission.table.default')}</Typography>
            )
        }},
    ];

    return (
        <Menu menuCurrent={'Commission'}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.title}>{t('menu.commission')}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputIcon onChange={ handleTiping } placeholder={t('commission.form.searchHere')} icon={<SearchIcon/>} labelText={t('commission.form.name')}/>
                </Grid>
                <Grid item xs={12} style={{width: 100}}>
                    <CustomTable rows={commissions} columns={columns}/>
                </Grid>
            </Grid>
        </Menu>
    )
}

export default Commission;