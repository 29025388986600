import React, { useReducer, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import authReducer from './authReducer';
import AuthContext from './authContext';

import axiosClient from '../../config/axios'

import {
    LOGIN,
    ERROR_LOGIN,
    LOGOUT,
    GET_LANGUAGES,
    RUNNING
} from '../../types'

const AuthState = (props) => {

    
    const initialState = {
        logged: JSON.parse(localStorage.getItem('user')) ? true : false,
        running: true,
        user: JSON.parse(localStorage.getItem('user')) || null,
        lang: (localStorage.getItem('envia_partner_lenguage')) ? localStorage.getItem('envia_partner_lenguage') : 'es-MX',
        error: null,
        autentication: false,
        languages: [],
    }

    const [ state, dispatch ] = useReducer(authReducer, initialState);
    const [cookies, removeCookie] = useCookies();

    useEffect(() => {
        if (cookies?._tpid && !state.logged) {
            axiosClient({
                method: 'GET',
                url:'/me',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies._tpid}`
                }
            }).then(resp => {
                console.log(resp.data);
                localStorage.removeItem('user');
                dispatch({
                    type: LOGIN,
                    payload: {
                        ...resp.data,
                        token: cookies._tpid
                    }
                });
                console.log(state.logged);
            })
            .catch(error => {
                localStorage.removeItem('user');
                removeCookie('_tpid', { path: '/' });
                dispatch({
                    type: RUNNING
                });
            });
        } else {
            dispatch({
                type: RUNNING
            });
        }
    }, [removeCookie]);

    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(state.user));
    }, [state.user])

    useEffect(() => {
        localStorage.setItem('envia_partner_lenguage', state.lang);
    }, [state.lang])

    const login = data => {
        const token = Buffer.from(`${data.username}:${data.password}`, 'utf8').toString('base64')
        axiosClient({
            method: 'GET',
            url:'/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${token}`
            }
        }).then(response => {
            dispatch({
                type:LOGIN,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type:ERROR_LOGIN,
                payload:error.response.data.message
            })
        });
    }

    const reloadSession = data => {
        axiosClient({
            method: 'GET',
            url:'/reload-login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${state.user.token}`
            }
        }).then(resp => {
            dispatch({
                type: LOGIN,
                payload: {
                    ...resp.data,
                    token: cookies._tpid
                }
            });
        })
        .catch(error => {
            dispatch({
                type:ERROR_LOGIN,
                payload:error.response.data.message
            })
        });
    }

    const logout = () => {
        localStorage.removeItem('user');
        removeCookie('_tpid', { path: '/' });
        dispatch({
            type: LOGOUT,
        })
        window.location.href = '/login';
    }

    const getLangs = () => {
        axiosClient({
            method: 'GET',
            url:`/config/get-languages`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${state.user.token}`
            }
        }).then(response => {
            dispatch({
                type:GET_LANGUAGES,
                payload: []
            })
        })
        .catch(error => {
            console.log(error);
        });

    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                reloadSession,
                logout,
                getLangs,
                dispatch,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState;