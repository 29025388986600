import React, { useRef, useState, useContext, useEffect }from 'react';
import { Link, useParams } from 'react-router-dom';

//Langs
import { useTranslation } from 'react-i18next';

// Material-UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { Box } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';

// Icons
import Receipt from '@material-ui/icons/Receipt';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PersonAdd from '@material-ui/icons/PersonAdd';

//Context
import AuthContext from '../../context/auth/authContext';
import ClientContext from '../../context/client/clientContext'

// Component
import Menu from '../../components/layouts/Menu';
import CustomTable from '../../components/info/CustomTable';
import FileCopyIcon from '@material-ui/icons/FileCopy';

//Modals
import AddProspect from '../../components/modals/AddProspect';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddStore = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const refCopyUrl = useRef(null);
    const [openAlert, setOpenAlert] = useState(false)
    const [token, setToken] = useState('')
    
    const { user } = useContext(AuthContext)
    const { prospects, getClientProspects} = useContext(ClientContext);
    var timeout;

    const copyUrl = (e) => {
        refCopyUrl.current.select();
        document.execCommand('copy');
        e.target.focus();
        setOpenAlert(true);
        setTimeout(() => {
           setOpenAlert(false); 
        }, 2000);
    }

    useEffect(() => {
        if(token){
            var el = document.createElement("INPUT");
            document.body.appendChild(el);
            el.value = process.env.REACT_APP_ENVIA_SHIP_HOSTNAME + '/registro?token=' + token;
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            setOpenAlert(true);
            setToken('');
        }
    }, [token]);

    useEffect(() => {
        getClientProspects();
    }, []);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const columns = [
        { id: 'id', label: t('clients.prospect.table.id'), view: (value) => {
            return (
                <Typography style={{textAlign: 'center' ,color: '#184d9f', fontWeight: 'bold'}}>#{value}</Typography>
            )
        }},
        { id: 'name', label: t('clients.prospect.table.name'), view: (value) => {
            return (
                <Typography style={{textAlign: 'center' ,color: '#a1a1a1', fontWeight: 'bold'}}>{value}</Typography>
            )
        }},
        { id: 'email', label: t('clients.prospect.table.email'), view: (value) => {
            return (
                <Typography style={{textAlign: 'center' }}> {value} </Typography>
            )
        }},
        { id: 'valid_until', label: t('clients.prospect.table.validUtil'), view: (value) => {
            return (
                <Typography style={{ textAlign: 'center' }}>{value}</Typography>
            )
        }},
        { id: 'created_at', label: t('clients.prospect.table.createdAt'), view: (value) => {
            return (
                <Typography style={{ textAlign: 'center' }}>{value}</Typography>
            )
        }},
        { id: 'token', label: t('clients.prospect.table.actions'), view: (value) => {
            return (
                <Button
                    variant="contained"
                    data-token={value}
                    color="primary"
                    onClick={(data) => {setToken(value)}}
                    style={{borderRadius: 10, textAlign: 'center'}}>
                        <FileCopyIcon style={{width: '20px'}}/>
                </Button>
                
            )
        }},
    ];

    return (
        <Menu menuCurrent={'Prospects'}>
            <Grid container spacing={3}>
                <Box style={{marginTop: 20, marginBottom: 20}}>
                    <Link to="/clients"><Typography variant="body1" className={classes.return}> <ArrowBackIcon/>{t('base.return')} </Typography></Link>
                </Box>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.title}>{t('clients.addStore')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">{t('clients.addStore.owners')}</Typography>
                    <Typography variant="body1">{t('clients.addStore.ownersContext')}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6" >{t('clients.addStore.shareLink')}</Typography>
                        <Grid container spacing={1} >
                            <Grid item xs={12}  sm={8}>
                                <input ref={refCopyUrl} className={classes.url} defaultValue={process.env.REACT_APP_ENVIA_SHIP_HOSTNAME + '/registro?partner_id=' + user.partnerId}/>
                            </Grid>
                            <Grid item xs={12}  sm={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Receipt/>}
                                    fullWidth
                                    onClick={copyUrl}
                                    style={{borderRadius: 10, fontSize: 16}}>

                                    {t('clients.addStore.btnCopy')}

                                </Button>

                            </Grid>
                            <Snackbar open={openAlert} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                <Alert onClose={handleClose} severity={`success`}>
                                    {t('clients.urlCopy')}
                                </Alert>
                            </Snackbar>
                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.title}>{t('clients.prospect')}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} style={{alignSelf: 'flex-end', marginBottom: 10, textAlign: 'end'}}>
                    <AddProspect text={t('clients.newProspect')} startIcon={<PersonAdd/>} styleButton={{borderRadius: 30, fontSize: 16}}></AddProspect>
                </Grid>
                <Grid item xs={12} style={{width: 100}}>
                    <CustomTable rows={prospects} columns={columns}/>
                </Grid>
            </Grid>
        </Menu>
    )
}


const useStyles = makeStyles((theme) => ({
    return: {
        display: 'flex',
        alignItems: 'center',
        color: '#1F3592',
        fontWeight: 'bold',
        fontStyle: 'none'
    },
    title: {
        fontWeight: 'bold',
    },
    paper: {
        padding: 20,
    },
    url: {
        backgroundColor: '#e1e1e1',
        padding: 10,
        borderRadius: 10,
        width: '100%',
        
    }
}));

export default AddStore;