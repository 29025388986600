import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-hot-toast';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

//icons
import CancelIcon from '@material-ui/icons/Cancel';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

// Context
import ClientContext from '../../context/client/clientContext'

//Langs
import { useTranslation } from 'react-i18next';

import {
    CLEAN,
} from '../../types'

const useStyles = makeStyles((theme) => ({
    formControl: {
      width: '100%',
    },
}));

const AddProspect = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { register_response, message, registerProspect, getClientProspects, dispatch, phone_codes, getPhoneCodes, languages, getLangs } = useContext(ClientContext)
    
    const [open, setOpen] = React.useState(props.open);
    const [ , setLoading] = useState(false);

    const [prospectrForm, setProspectrForm] = useState({
        name: '',
        email: '',
        phone: '',
        code: '',
        languages: '',
    })

    useEffect(() => {
        getPhoneCodes();
        getLangs();
    }, []);

    useEffect(() => {
        if (register_response == 'success') {
            setLoading(false)
            toast.success(message);
            getClientProspects();
            setOpen(false);
        }
        
        if (register_response == 'error') {
            setLoading(false)
            toast.error(message);
        }

        dispatch({
            type: CLEAN
        })

    }, [register_response, message]);

    const onChange = (e) => {
        setProspectrForm({
            ...prospectrForm,
            [e.target.name]: e.target.value,
        });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleSubmit(ev) {
        ev.preventDefault();
        registerProspect(prospectrForm);
    }

    return (
        <div>
            
            <Button 
                variant="contained"
                color="primary"
                startIcon={props.startIcon}
                style={props.styleButton}
                onClick={handleClickOpen}>
                {props.text}
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">{t('clients.prospects.modal.titile')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('clients.prospects.modal.context')}
                        </DialogContentText>
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                options = {languages}
                                getOptionLabel = {(option) => option.description}                            
                                name="languages"      
                                onChange={(e, val) => {
                                    setProspectrForm({
                                        ...prospectrForm,
                                        ["languages"]: val.id,
                                    });
                                }}
                                renderInput={(params) => (
                                <TextField {...params} name="languages" label={t('clients.prospects.modal.languages')}/>
                                )}
                            />
                        </FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label={t('clients.prospects.modal.name')}
                            type="text"
                            onChange={ onChange }
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="email"
                            name="email"
                            label={t('clients.prospects.modal.email')}
                            type="email"
                            onChange={ onChange }
                            fullWidth
                        />
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                options = {phone_codes}
                                getOptionLabel = {(option) => `( +${option.value} ) ${option.label}`}                            
                                name="code"      
                                onChange={(e, val) => {
                                    setProspectrForm({
                                        ...prospectrForm,
                                        ["code"]: val.value,
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} name="code" label={t('clients.prospects.modal.phone_code')}/>
                                )}
                            />
                        </FormControl>
                        <TextField
                            margin="dense"
                            id="phone"
                            name="phone"
                            label={t('clients.prospects.modal.phone')}
                            type="phone"
                            onChange={ onChange }
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button startIcon={ <CancelIcon/> } onClick={handleClose} style={{float: 'left'}} color="primary">
                            {t('clients.prospects.modal.close')}
                        </Button>
                        <Button startIcon={ <PersonAddIcon/> } color="primary" type="submit">
                            {t('clients.prospects.modal.register')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default AddProspect;
