import { faBloggerB, faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, List, ListItem, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { clsx } from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import '../Landing.css';

const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: '#eeeeee',
        paddingTop: 60,
        paddingBottom: 80,
    },
    container: {
        [theme.breakpoints.up(1600)]: {
            maxWidth: 1500,
        },
    },
    footerItemLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        textAlign:'left',
        [theme.breakpoints.down('sm')]: {
            textAlign:'center',
            alignItems: 'center',
        },
    },
    footerItemCenter: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign:'left',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: 1,
            backgroundColor: '#dfdfdf',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            width: 1,
            backgroundColor: '#dfdfdf',
        },
        [theme.breakpoints.down('md')]: {
            textAlign:'center',
        },
    },
    footerItemRight: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        textAlign:'right',
        [theme.breakpoints.down('sm')]: {
            textAlign:'center',
            alignItems: 'center',
        },
    },
    logoPartners: {
        maxWidth: 300,
        marginBottom: 10,
    },
    logoEnvia: {
        maxWidth: 200,
        marginBottom: 10,
    },
    nav: {
        display: 'flex',
        marginBottom: 10,
    },
    navLink: {
        color: '#00abec',
        textDecoration: 'none',
        fontFamily: 'Montserrat',
        '&:hover': {
            color: '#2ab4eb !important',
        },
    },
    btn: {
        color: '#ffffff',
        background: '#01a8f1',
        borderRadius: 0,
        padding: '0 25px',
        fontSize: '1.3rem',
        marginRight: '15px',
        fontWeight: '500',
        textTransform: 'lowercase',
        '&:hover': {
            background: '#2cb3ec',
            color: 'white',
        }
    },
    footerMessage: {
        color: '#00abec',
        marginBottom: 10,
    },
    copy: {
        fontSize: '.9rem',
    },
    socialList: {
        display: 'flex',
        paddingTop: 0,
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    socialItem: {
        width: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
    },
    socialLink: {
        width: 33,
        height: 33,
        margin: 5,
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: '100%',
        background: '#eeeeee',
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: 0,

        '&.facebook': {
            backgroundColor: '#0077f7',
        },
        '&.instagram': {
            background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
        },
        '&.linkedin': {
            backgroundColor: '#0079b8',
        },
        '&.twitter': {
            backgroundColor: '#00a5e1',
        },
        '&.youtube': {
            backgroundColor: '#ff0000',
        },
        '&.blogger': {
            backgroundColor: '#ff7c00',
        },
    },
}));

function Footer() {
    const { t } = useTranslation();
	const classes = useStyles();
	const today = new Date();
	const year = today.getFullYear();

	return (
		<Box className={classes.footer}>
            <Container className={classes.container}>
                <Grid container spacing={4}>
                    <Grid className={classes.footerItemLeft} item xs={12} md={4}>
                        <img className={classes.logoPartners} alt="Partners" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Logo-Partners.png`}/>
                        <Box className={classes.nav}>
                            <a className={classes.navLink} href="https://envia.com/terminos-y-condiciones" target="_blank" rel="noreferrer">
                                {t('landing.footer.hyperlink.termsandConditions')}
                            </a>
                            <Box alignSelf="center" px={1}>
                                <Typography className={classes.navLink}>
                                    |
                                </Typography>
                            </Box>
                            <a className={classes.navLink} href="https://envia.com/politicas-de-privacidad" target="_blank" rel="noreferrer">
                                {t('landing.footer.hyperlink.privacyPolicies')}
                            </a>
                        </Box>
                        <Typography className={classes.copy}>
                            © {year} {t('landing.footer.copyright')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.footerItemCenter} item xs={12} md={4}>
                        <Box>
                            <Typography className={classes.footerMessage}>
                                {t(`landing.knowmore.card2.text`)}
                            </Typography>
                            <Button href="https://envia.com" target="_blank" className={classes.btn}>
                                envia.com
                            </Button>
                        </Box>
                    </Grid>
                    <Grid className={classes.footerItemRight} item xs={12} md={4}>
                        <Box>
                            <img className={classes.logoEnvia} alt="Envia" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/countries/MEX/logo-dark.svg`}/>
                            <Box className={classes.nav}>
                                <a className={classes.navLink} href="https://enviapaqueteria.s3.us-east-2.amazonaws.com/uploads/Dossier+Tendencys+2021+M%C3%A9xico+-+Espa%C3%B1ol.pdf" target="_blank" rel="noreferrer">
                                    {t('landing.footer.hyperlink.ourServices')}
                                </a>
                                <Box alignSelf="center" px={1}>
                                    <Typography className={classes.navLink}>|</Typography>
                                </Box>
                                <a className={classes.navLink} href="https://help.envia.com/" target="_blank" rel="noreferrer">
                                    {t('landing.footer.hyperlink.faq')}
                                </a>
                            </Box>
                            <List className={classes.socialList}>
                                <ListItem className={classes.socialItem}>
                                    <a className={clsx('facebook', classes.socialLink)} href="https://www.facebook.com/enviacom" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>
                                </ListItem>
                                <ListItem className={classes.socialItem}>
                                    <a className={clsx('instagram', classes.socialLink)} href="https://www.instagram.com/envia.com_/" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </a>
                                </ListItem>
                                <ListItem className={classes.socialItem}>
                                    <a className={clsx('linkedin', classes.socialLink)} href="https://mx.linkedin.com/company/enviacom" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faLinkedinIn} />
                                    </a>
                                </ListItem>
                                <ListItem className={classes.socialItem}>
                                    <a className={clsx('twitter', classes.socialLink)} href="https://twitter.com/enviacom" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </a>
                                </ListItem>
                                <ListItem className={classes.socialItem}>
                                    <a className={clsx('youtube', classes.socialLink)} href="https://www.youtube.com/channel/UCykJMopq0kH4MVCSc1sO-hA/videos" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faYoutube} />
                                    </a>
                                </ListItem>
                                <ListItem className={classes.socialItem}>
                                    <a className={clsx('blogger', classes.socialLink)} href="https://blog.envia.com/" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faBloggerB} />
                                    </a>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
    	</Box>
	);
}

export default  withRouter(Footer);
