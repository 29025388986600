import toast, { useToasterStore, Toaster, ToastBar } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import CloseIcon from '@material-ui/icons/Close';

const DEFAULTS = Object.freeze({
    position: 'top-right',
    toastOptions: {
        style: {
            borderRadius: '4px',
            zIndex: '9999999999',
        },
        duration: 4000,
    },
    containerStyle: {
        zIndex: '9999999999',
        padding: '5px'
    },
});

const Toast = ({ limit, ...props }) => {
    const { toasts } = useToasterStore();
    const toasterProps = { ...DEFAULTS, ...props };
    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i > limit - 1) // Is toast index over limit?
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
    }, [toasts, limit]);
    return (
        <Toaster {...toasterProps}>
            {(t) => (
                <ToastBar toast={t}>
                    {({ icon, message }) => {
                        return(
                            <>
                                {icon}
                                {message}
                                {t.type !== 'loading' && (
                                    <CloseIcon style={{cursor: 'pointer'}} onClick={() => toast.dismiss(t.id)}/>
                                )}
                            </>
                        );
                    }}
                </ToastBar>
            )}
        </Toaster>
    );
};

Toast.propTypes = {
    limit: PropTypes.number,
};

Toast.defaultProps = {
    limit: 1,
};

export { Toast };
