import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    benefits: {
        backgroundColor: '#ffffff',
        paddingTop: 120,
        paddingBottom: 120,
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            paddingTop: 80,
            paddingBottom: 0,
        },
    },
    container: {
        [theme.breakpoints.up(1279)]: {
            maxWidth: 1140,
        },
        [theme.breakpoints.up(1600)]: {
            maxWidth: 1500,
        },
    },
    leftGrid: {
        paddingLeft: '0 !important',
        order: 1,
        [theme.breakpoints.up('lg')]: {
            position: 'absolute',
            left: 0,
            height: 'calc(100% - 120px)',
            marginTop: -10,
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0 !important',
            marginLeft: -24,
        },
    },
    rightGrid: {
        marginLeft: 'auto',
    },
    imageEnvia: {
        width: '100%',
        maxHeight: '100%',
    },
    title: {
        color: '#050629',
        fontSize: '2.6rem',
        fontWeight: 800,
        textAlign:'left',
        textTransform: 'uppercase',
        [theme.breakpoints.down('md')]: {
            textAlign:'center',
        },
    },
    titleText: {
        color: '#00abec',
        fontSize: '1.3rem',
        textAlign:'left',
        [theme.breakpoints.down('md')]: {
            textAlign:'center',
        },
        '&::after': {
            content: '""',
            display: 'block',
            height: 4,
            width: 150,
            marginTop: 10,
            backgroundColor: '#00abec',
            [theme.breakpoints.down('md')]: {
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
    },
    benefit: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'initial',
            alignItems: 'flex-start',
        },
    },
    benefitContent: {
        textAlign:'left',
        [theme.breakpoints.down('md')]: {
            textAlign:'center',
        },
    },
    blueStar: {
        width: 20,
        height: 20,
        marginTop: 5,
        marginRight: 8,
        display: 'inline-block',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    },
    benefitTitle: {
        color: '#00abec',
        textAlign:'left',
        fontWeight: 800,
        marginBottom: 5,
        [theme.breakpoints.down('sm')]: {
            textAlign:'center',
        },
    },
    benefitText: {
        color: '#606060',
        textAlign:'left',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            textAlign:'center',
        },
    },
    buttonContainer: {
        [theme.breakpoints.down('md')]: {
            textAlign:'center',
        },
    },
    btn: {
        color: '#ffffff',
        background: '#01a8f1',
        borderRadius: 0,
        padding: '5px 32px',
        fontSize: '1rem',
        marginRight: '15px',
        fontWeight: '400',
        '&:hover': {
            background: '#2cb3ec',
            color: 'white',
        },
        [theme.breakpoints.down('md')]: {
            display: 'inline-block',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
}));

function Benefits() {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={classes.benefits}>
            <Container className={classes.container}>
                <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item xs={12} lg={4} className={classes.leftGrid}>
                        <img className={classes.imageEnvia} alt="App Envia" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-App-envia.png`}/>
                    </Grid>
                    <Grid item xs={12} lg={8} className={classes.rightGrid}>
                        <Container>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    <Box>
                                        <Typography className={classes.title} gutterBottom variant="h2" component="h2">
                                            {t('landing.benefits.title')}
                                        </Typography>
                                        <Typography className={classes.titleText} gutterBottom variant="h6" component="h6">
                                            {t('landing.benefits.subtitle')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box className={classes.benefit}>
                                        <img className={classes.blueStar} alt="Blue star" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-azul.png`}/>
                                        <div className={classes.benefitContent}>
                                            <Typography className={classes.benefitTitle} gutterBottom variant="h5" component="h5">
                                                {t('landing.benefits.box1.title')}
                                            </Typography>
                                            <Typography className={classes.benefitText} gutterBottom variant="h6" component="h6">
                                                {t('landing.benefits.box1.text')}
                                            </Typography>
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box className={classes.benefit}>
                                        <img className={classes.blueStar} alt="Blue star" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-azul.png`}/>
                                        <div className={classes.benefitContent}>
                                            <Typography className={classes.benefitTitle} gutterBottom variant="h5" component="h5">
                                                {t('landing.benefits.box3.title')}
                                            </Typography>
                                            <Typography className={classes.benefitText} gutterBottom variant="h6" component="h6">
                                                {t('landing.benefits.box3.text')}
                                            </Typography>
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box className={classes.benefit}>
                                        <img className={classes.blueStar} alt="Blue star" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-azul.png`}/>
                                        <div className={classes.benefitContent}>
                                            <Typography className={classes.benefitTitle} gutterBottom variant="h5" component="h5">
                                                {t('landing.benefits.box4.title')}
                                            </Typography>
                                            <Typography className={classes.benefitText} gutterBottom variant="h6" component="h6">
                                                {t('landing.benefits.box4.text')}
                                            </Typography>
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box className={classes.benefit}>
                                        <img className={classes.blueStar} alt="Blue star" src={`${process.env.REACT_APP_S3_PATH}/uploads/landing/images/partners/Img-Destello-azul.png`}/>
                                        <div className={classes.benefitContent}>
                                            <Typography className={classes.benefitTitle} gutterBottom variant="h5" component="h5">
                                                {t('landing.benefits.box2.title')}
                                            </Typography>
                                            <Typography className={classes.benefitText} gutterBottom variant="h6" component="h6">
                                                {t('landing.benefits.box2.text')}
                                            </Typography>
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} className={classes.buttonContainer}>
                                    <Button target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScBTW0l3qGgvZgZwgaXUmBEZe8_S-J_mz2Nh8hGYEMhObgQ5w/viewform" variant="contained" className={classes.btn}>
                                        {t('landing.benefits.form.button')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default  withRouter(Benefits);
