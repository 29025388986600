import React from 'react';

//Langs
import { useTranslation } from 'react-i18next';

// Material-UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';


// Component
import Menu from '../../components/layouts/Menu';



const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold',
    },
    paper: {
        padding: 20,
    },
}));

const Support = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Menu menuCurrent={'Support'}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.title}>{t('support.title')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">{t('support.subTitle')}</Typography>
                    <Typography variant="body1">{t('support.subTitleContext')}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6" >{t('support.available')}</Typography>
                        <Grid container style={{marginTop: 10}}>
                            <Grid item xs={8}>
                                <a href={`mailto:support.partners@envia.com"`}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        style={{borderRadius: 10, fontSize: 16}}>

                                        {t('support.contact')}

                                    </Button>
                                </a>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
        </Menu>
    )
}

export default Support;