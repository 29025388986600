import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend';

const lang = localStorage.getItem('envia_partner_lenguage') || 'es-MX';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
	order: ['navigator', 'localStorage'],
	caches: ['localStorage'],
	fallbackLng: lang,
	debug: false,
	defaultNS: 'translation',
	interpolation: {
	  	escapeValue: false // not needed for react as it escapes by default
	},
    backend: {
		loadPath: (lng) => {
			return `${process.env.REACT_APP_S3_PATH}/uploads/language/envia-partners/${lng}.json`;
		},
      	crossDomain: true,
	},
	react: {
		useSuspense: false
	},
});

export default i18n;